* {
    margin: 0px;
    padding: 0px;
}
html {height: 100%;}
body {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 14px;
}
h1, .h1 {
    font-size: 36px;
    font-weight: 400;
    padding-bottom: 25px;
}
h2, .h2 {
    font-size: 30px;
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 10px;
}
h3, .h3 {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 10px;
}
h4, .h4 {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 10px;
    outline: none;
}
h5, .h5 {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 10px;
}
h6, .h6 {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 10px;
}
img {
    max-width: 100%;
}
a {
    color: #cf1333;
}
p {
    line-height: 26px;
    padding-bottom: 20px;
}
.small_indent p {
    padding-bottom: 0;
}
.small_indent p:last-child {
    padding-bottom: 20px;
}
table {
    border-collapse: collapse;
    table-layout: fixed;
    margin: 0;
}
table td {
    vertical-align: top;
    line-height: 26px;
}
table .icon {
    width: 42px;
}
ul, ol {
    margin-left: 25px;
}
li {
    line-height: 26px;
}
ol .content {
    list-style-type: none;
    margin-left: -25px;
    padding-bottom: 5px;
    padding-top: 10px;
}
ol .content a {
    border-bottom: 1px dotted #B00015;
    color: #B00015;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}
hr {
    border: none;
    border-top: 1px solid #B00015;
    margin-bottom: 10px;
}

.flex-justify-center{
    -webkit-box-pack:center!important;
    -ms-flex-pack:center!important;
    justify-content:center!important
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="num"],
input[type="tel"] {
    height: 40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 15px;
    padding: 0 5px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 14px;
    width: 100%;
    border: 1px solid #B00015;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
select {
    height: 40px;
    line-height: 34px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 15px;
    padding: 0 5px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 14px;
    width: 100%;
    outline: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    opacity: 0;
    position: relative;
    z-index: 3;
}
.select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    border: 1px solid #B00015;
    margin: 0 0 15px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 14px;
    position: relative;
    z-index: 3;
}
.select_arr {
    position: absolute;
    z-index: 2;
    width: 38px;
    height: 38px;
    right: 0;
    top: 0;
    background: url('../i/ic_arrow_drop_down_black_24px.svg') 50% 50% no-repeat;
}
.select_val {
    position: absolute;
    z-index: 1;
    padding: 0 35px 0 10px;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
textarea {
    height: 140px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 5px;
    padding: 5px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 14px;
    width: 100%;
    resize: none;
    border: 1px solid #B00015;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
input[type="submit"] {
    height: 40px;
    background: #B00015;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 5px;
    padding: 0 25px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;
    float: right;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
  transition: all 0.265ms ease-out;
}

input[type="range"]:not(:last-of-type) {
  margin-top: 0;
  margin-bottom: 2.5em;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #ededef;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #ededef;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0 0 8px 1px #b00015,
    0 0 16px 4px #fff;
  border: 3px solid #fff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #b00015;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 0 0 8px 1px #b00015,
    0 0 16px 4px #fff;
  border: 3px solid #fff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #b00015;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #b00015;
}

input[type="range"]:focus::-moz-range-track {
  background: #b00015;
}

datalist {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.slider_reducer {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.cloud-disk-tickmarks option {
    min-width: 6px;
}

.cloud-disk-tickmarks option.tiny {
    min-width: 0px;
}

.cloud-disk-tickmarks option:first-child {
    margin-left: -10px;
/*    margin-right: -5px;*/
}

.cloud-disk-tickmarks option:last-child {
/*    margin-right: -10px;*/
}

.disk_opt_active {
    background: #b00015;
    color: #fff;
}

@media screen and (max-width: 435px) {
    .cloud-disk-tickmarks option {
        font-size: 0.6em;
    }
    .cloud-disk-tickmarks option.tiny {
        min-width: 5px;
    }
}

.layout {
    position: relative;
    min-height: 100%;
    min-width: 1000px;
}
.layout.error404 {
    background: #000;
}
.reducer {
    max-width: 1280px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
}


/* Header */
header {
    height: 54px;
    padding-top: 18px;
    position: relative;
    background: #fff;
}
.lang {
    float: right;
    line-height: 35px;
    white-space: nowrap;
    margin-right: 25px;
    position: relative;
    z-index: 10;
    cursor: pointer;
    padding-right: 0;
}
.lang_curr {
    position: relative;
    padding-right: 15px;
}
.first_nav_li .lang_curr {
    font-size: 14px;
}
.lang_curr:after {
    content: '';
    height: 35px;
    width: 10px;
    position: absolute;
    top: 0;
    right: 0;
    background: url('../i/ic_arrow_drop_down_white.svg') 50% 50% no-repeat;
    s-webkit-background-size: cover;
    sbackground-size: cover;
}
.lang_list {
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 100%;
    -webkit-box-shadow: 0 2px 3px rgba(24,24,24,.5);
    -moz-box-shadow: 0 2px 3px rgba(24,24,24,.5);
    box-shadow: 0 2px 3px rgba(24,24,24,.5);
}
.lang_list_container {
    background: #fff;
}
.lang_list_container .radio .icon,
.lang_list_container .radio.left .icon {
    display: none;
}
.lang_list_container .radio a {
    line-height: 35px;
}
.lang a {
    display: block;
    width: auto;
    padding-left: 5px;
}
.lang_list a {
    color: #B00015;
    /*padding-right: 0;*/
    /*padding: 0 5px 0 35px;*/
    padding: 0 5px;
}
.lang_list a:hover {
    background-color: #000;
    color: #fff;
}
/*.lang .lang_ru {
    background: url('../i/ru.svg') 5px 50% no-repeat;
    -webkit-background-size: auto 15px;
    background-size: auto 15px;
}
.lang .lang_en {
    background: url('../i/um.svg') 5px 50% no-repeat;
    -webkit-background-size: auto 15px;
    background-size: auto 15px;
}
.lang .lang_ua {
    background: url('../i/ua.svg') 5px 50% no-repeat;
    -webkit-background-size: auto 15px;
    background-size: auto 15px;
}*/
.header_l {
    float: left;
    margin-right: -100%;
    position: relative;
    z-index: 1;
}
.header_r {
    line-height: 40px;
    float: right;
    width: 300px;
    margin-left: -300px;
    position: relative;
    z-index: 1;
}
.logo {
    display: block;
    text-decoration: none;
    width: 300px;
}
.logo img {
    vertical-align: top;
}
.logo_txt {
    display: inline-block;
    padding-top: 4px;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 0;
    color: #464646;
}
.header_enter {
    float: left;
    padding-left: 20px;
}
.header_enter_btn {
    height: 40px;
    width: 40px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: #e1e1e1 url('../i/ic_exit_to_app_black_48px.svg') 50% 50% no-repeat;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    cursor: pointer;
}
.header_btns {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    padding-top: 8px;
    padding-left: 50px;
}
.header_btn {
    color: #b00015;
    text-decoration: none;
    padding: 0 20px 0 25px;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    margin-bottom: 3px;
}
.header_btn.btn1 {
    background: url('../i/ic_tune_black_24px.svg') 0 100% no-repeat;
    -webkit-background-size: auto 100%;
    background-size: auto 100%;
}
.header_btn.btn2 {
    background: url('../i/ic_card_giftcard_black_24px.svg') 0 100% no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.header_contacts {
    font-size: 20px;
    line-height: 20px;
    text-align: right;
}
.header_contacts a {
    text-decoration: none;
    color: #000;
    sborder-bottom: 1px dotted #b00015;
    white-space: nowrap;
    font-weight: 400;
}
.header_contacts span {
    text-decoration: none;
    color: #b00015;
    border-bottom: 1px solid #b00015;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.breadcrumbs {
    padding-bottom: 10px;
}
/* Header */

/* Navigation */
.mob_nav_change {
    cursor: pointer;
    display: none;
    padding-left: 30px;
}
nav {
    height: 35px;
    background: #B00015;
    position: relative;
    z-index: 16;
}
.nav_icon {
    float: left;
    width: 0;
    background: url('../i/ic_menu_white.svg') 50% 50% no-repeat;
    -webkit-background-size: 32px 32px;
    background-size: 32px 32px;
    cursor: pointer;
}
.mode_link {
    display: none;
}
.nav_phone {
    display: none;
}
nav .menu_nav_icon {
    width: 0;
    background: url('../i/ic_menu_red.svg') 50% 50% no-repeat;
    -webkit-background-size: 32px 32px;
    background-size: 32px 32px;
    cursor: pointer;
    margin: 0;
}
nav .menu_nav_close {
    width: 0;
    height: 0px;
    line-height: 35px;
    text-align: center;
    z-index: 3;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 20px;
    color: #B00015;
    margin: 0;
    overflow: hidden;
}
nav ul {
    list-style-type: none;
    float: left;
    margin-right: -100%;
    margin-left: 0;
    height: 35px;
    border-collapse: collapse;
    table-layout: fixed;
    position: relative;
}
nav li {
    float: left;
    margin-right: 50px;
    text-transform: uppercase;
    line-height: 35px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    position: relative;
}
nav .first_nav_li {
    display: none;
}
nav .nav_drop_down_handler,
nav a {
    display: block;
    width: 100%;
    height: 35px;
    background: transparent;
    color: #fff;
    text-decoration: none;
    font-style: normal;
}
nav .nav_drop_down_handler {
     cursor: pointer;
     padding-right: 15px;
     position: relative;
 }
nav .nav_drop_down_handler:after {
    content: '';
    height: 35px;
    width: 10px;
    position: absolute;
    top: 0;
    right: 0;
    background: url(../i/ic_arrow_drop_down_white.svg) 50% 65% no-repeat;
}
nav .curr .nav_drop_down_handler,
nav .curr a {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 3px solid #c77079;
}
nav .nav_drop_down {
    background: #fff;
    left: 0;
    top: 100%;
    position: absolute;
    -webkit-box-shadow: 0 2px 3px rgba(24,24,24,.5);
    -moz-box-shadow: 0 2px 3px rgba(24,24,24,.5);
    box-shadow: 0 2px 3px rgba(24,24,24,.5);
    overflow: hidden;
    line-height: 0;
    font-size: 0;
    text-align: left;
    white-space: nowrap;
}
nav .nav_drop_down ul {
    height: auto;
    margin: 0;
}
nav .nav_drop_down li {
    display: block;
    vertical-align: top;
    font-size: 14px;
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
    height: auto;
}
nav .nav_drop_down li a {
    color: #B00015;
    border: none;
    height: auto;
    line-height: 28px;
    padding: 3px 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
nav .nav_drop_down li a:hover {
    color: #fff;
    background: #000;
}
nav span {
    text-transform: lowercase;
}
nav .enter_btn {
    float: right;
    width: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    padding-right: 25px;
    background: url('../i/ic_exit_to_app_white_48px.svg') 100% 50% no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
    text-transform: uppercase;
}
.enter_block {
    position: absolute;
    right: 20px;
    top: 35px;
    -webkit-box-shadow: 0 2px 3px rgba(24,24,24,.5);
    -moz-box-shadow: 0 2px 3px rgba(24,24,24,.5);
    box-shadow: 0 2px 3px rgba(24,24,24,.5);
}
.enter_block .enter_block_black {
    overflow: hidden;
    height: auto;
    background: rgba(255,255,255,.95);
    background: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-left: 0;
}
.enter_block .enter_block_black li {
    float: none;
    margin: 0;
    padding: 0;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.enter_block .enter_block_black a {
    height: 35px;
    line-height: 35px;
    padding: 0 35px 0 15px;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    width: auto;
    color: #B00015;
    white-space: nowrap;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.enter_block .enter_block_black a:hover {
    color: #fff;
    background: #000 url('../i/ic_arrow_forward_white.svg') 100% 50% no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}
.enter_block .enter_block_black a span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: relative;
    top: 3px;
}
.enter_block .enter_block_black a .mirohost_icon {
    background: url('../i/mirohost_icon.svg') 50% 50% no-repeat, url('../i/mirohost_icon_white.svg') 150% 150% no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_block .enter_block_black a .imena_icon {
    background: url('../i/imena_icon.svg') 50% 50% no-repeat, url('../i/imena_icon_white.svg') 150% 150% no-repeat;;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_block .enter_block_black a:hover .mirohost_icon {
    background: url('../i/mirohost_icon_white.svg') 50% 50% no-repeat, url('../i/mirohost_icon.svg') 150% 150% no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_block .enter_block_black a:hover .imena_icon {
    background: url('../i/imena_icon_white.svg') 50% 50% no-repeat, url('../i/imena_icon.svg') 150% 150% no-repeat;;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_block_arr {
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 14px 14px 14px;
    border-color: transparent transparent #ffffff transparent;
    right: 25px;
    top: -13px;
}
.enter_block_arr_red {
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 16px 15px;
    border-color: transparent transparent #B00015 transparent;
    right: 24px;
    top: -14px;
}
.enter_block_close {
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    z-index: 3;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 20px;
    color: #B00015;
}
.enter_block_content {
    background: #fff;
    padding: 20px;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: 0 0 5px rgba(23,25,27,.5);
    -moz-box-shadow: 0 0 5px rgba(23,25,27,.5);
    box-shadow: 0 0 5px rgba(23,25,27,.5);
    position: relative;
    z-index: 1;
    min-width: 320px;
}
.enter_block_content table {
    width: 320px;
}
.enter_block_login_content table,
.enter_block_sms_content table {
    margin-top: 20px;
}
.enter_block_content td {
    padding-bottom: 10px;
    text-align: center;
}
.enter_block_content tr:last-child td {
    padding-bottom: 0px;
}
.enter_block_content input {
    width: 100%;
}
.enter_block_content a {
    color: #B00015;
    display: inline;
    text-decoration: underline;
}
.enter_block_content .tabs {
    height: 45px;
    width: 320px;
}
.enter_block_content .tab {
    height: 45px;
}
.enter_block_content .tab.curr {
    height: 44px;
    cursor: auto;
    border-bottom: 3px solid #B00015;
}
.enter_block .enter_block_black a span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: relative;
    top: 3px;
}
.enter_block .enter_block_black a .mirohost_icon {
    background: url('../i/mirohost_icon.svg') 50% 50% no-repeat, url('../i/mirohost_icon_white.svg') 150% 150% no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_block .enter_block_black a .imena_icon {
    background: url('../i/imena_icon.svg') 50% 50% no-repeat, url('../i/imena_icon_white.svg') 150% 150% no-repeat;;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_block .enter_block_black a:hover .mirohost_icon {
    background: url('../i/mirohost_icon_white.svg') 50% 50% no-repeat, url('../i/mirohost_icon.svg') 150% 150% no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_block .enter_block_black a:hover .imena_icon {
    background: url('../i/imena_icon_white.svg') 50% 50% no-repeat, url('../i/imena_icon.svg') 150% 150% no-repeat;;
    -webkit-background-size: cover;
    background-size: cover;
}
/* Navigation */

.tabs .expand-title {
    display: block;
    padding: .65rem 1rem 0;
    background-color: inherit;
    color: inherit;
    text-decoration: none
}

.tabs>ul.tabs-list {
    list-style: none inside;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    width: 100%;
    margin-top: .5rem;
    margin-bottom: 1px;
    position: relative;
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height .3s ease;
    transition: max-height .3s ease
}

.tabs>ul.tabs-list a,.tabs>ul.tabs-list>li {
    position: relative
}

.tabs>ul.tabs-list>li {
    display: block;
    background-color: #fff;
    color: #1d1d1d;
    margin: 0 0 -1px 0
}

.tabs>ul.tabs-list>li>a {
    padding: .5rem 1rem;
    background-color: inherit;
    color: inherit;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.tabs>ul.tabs-list>li>a>[class*=mif-] {
    margin: 0 4px
}

.tabs>ul.tabs-list>li:hover {
    background-color: #f8f8f8
}

.tabs>ul.tabs-list>li.active {
    background-color: #dfdfdf
}

.tabs-expand {
    border: none
}

.tabs-expand>ul.tabs-list {
    border: 0;
    border-bottom: 1px solid #dfdfdf;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    margin-top: 0;
    height: auto;
    max-height: none;
    overflow: visible
}

.tabs-expand>ul.tabs-list>li {
    border-bottom: 1px solid #dfdfdf
}

.tabs-expand>ul.tabs-list>li.active {
    border: 1px solid #dfdfdf;
    border-bottom: none;
    background-color: #fff
}

.tabs-expand>ul.tabs-list .d-menu {
    position: absolute;
    width: auto;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(227,228,229,.7),-.5px 0 1px 0 rgba(227,228,229,.7);
    box-shadow: 2px 2px 2px 0 rgba(227,228,229,.7),-.5px 0 1px 0 rgba(227,228,229,.7)
}

.tabs-expand.bottom>ul.tabs-list {
    border: none;
    border-top: 1px solid #dfdfdf
}

.tabs-expand.bottom>ul.tabs-list>li {
    border: none;
    border-top: 1px solid #dfdfdf
}

.tabs-expand.bottom>ul.tabs-list>li.active {
    border: 1px solid #dfdfdf;
    border-top: none
}

.tabs-expand.vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.tabs-expand .expand-title,.tabs-expand .hamburger {
    display: none
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-group,.tabs-expand:not(.vertical):not(.bottom) ul.tabs-pills,.tabs-expand:not(.vertical):not(.bottom) ul.tabs-text {
    border-bottom: none
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-text>li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    border: none
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-text>li:hover {
    background: 0 0
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-text>li.active {
    font-size: 3rem
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-text>li>a {
    height: 100%;
    padding: .5rem .5rem 0;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-text>li:not(.active) a {
    padding-bottom: 10px
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-group {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-group>li {
    border: 1px solid #dfdfdf;
    width: 100%
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-group>li.active {
    background: #e4e4e4;
    font-weight: 500
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-pills>li {
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: 10px
}

.tabs-expand:not(.vertical):not(.bottom) ul.tabs-pills>li.active {
    font-weight: 500;
    border-color: #dfdfdf;
    background: #e4e4e4
}

/* Content */
section {
    padding: 20px 0;
}

section ul {
    margin: 10px 25px 10px;
}
section.no_indent {
    padding: 0;
}
section.contact {
    padding: 20px 0 0;
}
.page h1:first-child {
    padding-bottom: 25px;
}

.slider {
    font-weight: 400;
    text-decoration: none;
    position: relative;
}
.slider.min {
    margin: 20px 0;
}
.slider.order {
    margin: 0;
}
.slider_elem {
    min-height: 145px;
    padding-bottom: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}
.slider_hover_link {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
}

.black {
    text-align:center;
    background-color: #000;
}

.darkgray {
    text-align:center;
    background-color: #212121;
}

h2 #about  {
    color: #b00015;
    }

.slider.min .slider_elem {
    height: auto;
    padding: 30px 0 35px;
}

.slider_elem_content {
    padding: 5px 35px;
}

.slider_elem.bg1 {
    /* background: #000 url('../i/slider-bg-001.jpg') 50% 0 no-repeat; */
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg2 {
    /* background: #e3e3e3 url('../i/slider-bg-017.jpg') 50% 0 no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg3 {
    /* background: #e3e3e3 url('../i/slider-bg-004.jpg') 50% 50% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg4 {
    /* background: #e3e3e3 url('../i/bg-white.jpg') 50% 50% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    border-top: 1px solid #B00015;
    border-bottom: 1px solid #B00015;
    color: #0B5B80;
}
.slider_elem.bg5 {
    -webkit-background-size: cover;
    background-size: cover;
    color: #000;
}
.slider_elem.bg6 {
    /* background: #e3e3e3 url('../i/slider-bg-005.jpg') 50% 100% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg7 {
    /* background: #e3e3e3 url('../i/slider-bg-010.jpg') 50% 100% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg8 {
    /* background: #e3e3e3 url('../i/slider-bg-013.jpg') 50% 50% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg9 {
    /* background: #e3e3e3 url('../i/slider-bg-019-reviews.jpg') 50% 50% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg10 {
    /* background: #e3e3e3 url('../i/slider-bg-003.jpg') 50% 50% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg11 {
    /* background: #e3e3e3 url('../i/slider-bg-022-payments.jpg') 50% 50% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}
.slider_elem.bg12 {
    /* background: #e3e3e3 url('../i/slider-bg-011.jpg') 50% 50% no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    background: #000;
}

.slider_elem.bg14 {
    background: #000;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    padding: 35px;
}

.slider_elem.bg15 {
    background: #333333;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
}
.cols .cols_block.bg27 {
    background: url('../i/ic_help_black_36px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg28 {
    background: url('../i/ic_settings_input_component_black_36px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}

.slider h1 {
    /*padding-top: 42px;*/
    padding-top: 25px;
    padding-bottom: 13px /*12px*/;
}
.slider h2 {
    /*font-weight: 400;*/
    padding-bottom: 15px;
    line-height: 40px;
    padding-top: 20px;
}
.slider h2 span {
    font-size: 30px;
    line-height: 30px;
}
.slider.min h1 {
    padding: 0 0 10px;
}
.slider.min h2 {
    padding: 0 0 10px;
    line-height: normal;
}
.slider ol,
.slider ul {
    margin-left: 22px;
    font-size: 14px;
    padding-bottom: 12px;
}
.slider ol li,
.slider ul li {
    line-height: 24px;
}
.slider ul {
    list-style-type: none;
}
.slider .red_btn {
    /*display: inline-block;
    background: #b00015;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    vertical-align: middle;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;*/
    display: inline-block;
    background: #b00015;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}
.slider .three_cols {
    width: 100%;
}
.slider .three_cols .td {
    padding-right: 15px;
}
.slider .three_cols .td:last-child {
    padding-right: 0;
}
.slider a.btn {
    float: left;
    height: 46px;
    line-height: 46px;
    color: #fff;
    border: 2px solid #fff;
    padding: 0 17px;
    text-transform: uppercase;
    font-size: 20px;
    text-decoration: none;
}
.slider a {
    color: #fff;
    text-decoration: none;
}
.slider .info {
    font-size: 14px;
    /*margin-top: 42px;*/
    margin-top: 20px;
    padding-left: 100px;
}
.slider .info.hosting {
    background: url('../i/hosting.svg') 32px 50% no-repeat;
    -webkit-background-size: 40px auto;
    background-size: 40px auto;
}
.slider .info.evps {
    background: url('../i/cloud-server-icon.svg') 32px 50% no-repeat;
    -webkit-background-size: 50px auto;
    background-size: 50px auto;
}
.slider .info.cloud {
    background: url('../i/cloud-hosting-server-icon.svg') 32px 50% no-repeat;
    -webkit-background-size: 50px auto;
    background-size: 50px auto;
}
.slider .info.server {
    background: url('../i/servers.svg') 32px 50% no-repeat;
    -webkit-background-size: 50px auto;
    background-size: 50px auto;
}
.slider .info.stream {
    background: url('../i/stream-server-ico.svg') 32px 50% no-repeat;
    -webkit-background-size: 50px auto;
    background-size: 50px auto;
}
.slider .info ul {
    padding: 0;
    margin: 0;
    stext-transform: uppercase;
    font-size: 14px;
}
.slider .info li {
    position: relative;
    padding-left: 20px;
    padding-top: 5px;
    line-height: 20px;
}
.slider .info li:before {
    content: '+';
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    left: 0;
    top: 5px;
}
.slider .note {

}
.slider.min .note {
    padding: 0;
    font-size: 18px;
    line-height: 28px;
}
/*
.slider .bg2 {
    font-weight: 400;
}
.slider .bg2 h2 {
    font-size: 40px;
    font-weight: 400;
}
.slider .bg2 ul {
    font-size: 14px;
}
.slider .bg2 a.btn {
    float: right;
    color: #fff;
    border-color: #fff;
    background: url('../i/ic_play_circle_outline_white_48px.svg') 10px 50% no-repeat;
    -webkit-background-size: 32px 32px;
    background-size: 32px 32px;
    padding-left: 56px;
}
*/
.slider .underline {
    border-bottom: 1px dotted #333;
}
.slider .red {
    color: #b10005;
}
.order .select {
    margin-top: 60px;
    color: #000;
    background: rgba(255,255,255,0.85);
    font-weight: 400;
}
.slider .additional_nav {
    margin: 0;
    padding: 0;
}
.slider .additional_nav.big {
    font-size: 0 !important;
    line-height: 0 !important;
}
.slider .additional_nav.big li {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 7px;
}
.slider .additional_nav.big li {
    font-size: 14px;
    line-height: 16px;
    width: 25%;
    display: inline-block;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 15px;
}
.slider .additional_nav a {
    color: #fff;
}

.order_steps {

}
.order_step {

}
.order_steps_total {
    overflow: hidden;
    text-align: center;
    font-size: 0;
    padding: 0 0 30px;
}
.order_steps_total .order_steps_btn {
    float: none;
    display: none;
}
.order_steps_total_prev,
.order_steps_total_num,
.order_steps_total_next {
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
    margin: 0 20px;
    vertical-align: top;
}
.order_steps_total_prev {
    width: 40px;
    height: 40px;
    background: url('../i/ic_arrow_back_red.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    cursor: pointer;
}
.order_steps_total_next {
    width: 40px;
    height: 40px;
    background: url('../i/ic_arrow_forward_red.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    cursor: pointer;
}
.payment-form .order_steps_total_prev {
    margin-left: 0;
}
.payment-form .order_steps_total_next {
    margin-right: 0;
}
.order_steps_total_prev.disabled {
    background: url('../i/ic_arrow_back_gray.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    cursor: auto;
}
.order_steps_total_next.disabled {
    background: url('../i/ic_arrow_forward_gray.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    cursor: auto;
}

.hosting_description {
    width: 100%;
    margin: 0 0 40px;
    color: #fff;
}
.hosting_description_bg {
    background: #212121;
    padding-top: 30px;
}
.hosting_description .td {
    position: relative;
    /* overflow: hidden; */
}
.hosting_description .hosting_description_action {
    width: 120px;
    height: 120px;
    background: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    color: #b00015;
}
.hosting_description .hosting_description_action span {
    display: block;
    font-size: 30px;
    padding: 20px 0 0;
}
.hosting_description .hosting_description_elem_bg {
    sbackground: #b00015;
    sposition: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-bottom: 115px;
    stop: 10px;
    /* overflow: hidden; */
}
.hosting_description .hosting_description_elem_bg.big {
    position: relative;
    margin-bottom: -10px;
}
.hosting_description .hosting_description_elem_bg.big:before {
    content:'';
    position: absolute;
    left: 0;
    top: -20px;
    background: #b00015;
    height: 20px;
    width: 100%;
}
.hosting_description .hosting_description_elem_bg.big .hosting_description_icon {
    margin-top: 17px;
    position: relative;
    top: -10px;
}
.hosting_description .hosting_description_elem_bg.big h2,
.hosting_description .hosting_description_elem_bg.big p {
    margin-top: -10px;
}
.hosting_description.no_size .hosting_description_elem_bg {
    height: auto;
    padding-bottom: 20px;
}
.hosting_description .tr:last-child .curr {
    border-top: none;
}
.hosting_description .separator {
    background-color: transparent;
    cursor: auto;
}
.hosting_description a.td {
    color: #fff;
    text-decoration: none;
    width: 33%;
    background: #b00015;
}
.hosting_description h2 {
    text-align: center;
    padding-bottom: 12px;
}
.hosting_description .line {
    text-align: center;
    padding-bottom: 12px;
    font-size: 30px;
    font-weight: 400;
    padding-top: 10px;
}
.hosting_description_1 {
    background: url('../i/hosting-red.svg') 50% 50% no-repeat;
}
.hosting_description_2 {
    background: url('../i/cloud-server-icon-red.svg') 50% 50% no-repeat;
    background-size: 60%;
}
.hosting_description_3 {
    background: url('../i/servers-red.svg') 50% 50% no-repeat;
}

.hosting_description_4 {
    background: url('../i/colocation-red.svg') 50% 50% no-repeat;
}

.hosting_description_5 {
    background: url('../i/data-center-red.svg') 50% 50% no-repeat;
}
.hosting_description_6 {
    background: url('../i/cloud-hosting-server-icon-red.svg') 50% 50% no-repeat;
    background-size: 65%;
}
.hosting_description_7 {
    background: url('../i/stream-server-ico-red.svg') 50% 50% no-repeat;
    background-size: 60%;
}

.hosting_description_icon {
    width: 120px;
    height: 120px;
    margin: 17px auto 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background-color: #fff;
    position: relative;
}
.h-baner {
    max-width: 500px;
    margin-bottom: 0;
}
.h-baner .hosting_description_elem_bg {
    text-align: left;
}
.h-baner .hosting_description_icon {
    margin: 17px 25px;
    float: left;
}
.h-baner .hosting_description_order {
    clear: left;
}
.mh-auto {
    margin-left: auto;
    margin-right: auto;
}
.hosting_description p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    padding: 0 35px;
}
.hosting_description_order {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
.hosting_description_price {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}
.hosting_description_price span {
    font-size: 40px;
}
.hosting_description_btn {
    background: #fff;
    color: #b00015;
    font-weight: 400;
    letter-spacing: 1px;
    display: block;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    margin-top: 18px;
}
.hosting_description .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 28px 27px 28px;
    border-color: transparent transparent #000000 transparent;
    position: absolute;
    z-index: 2;
    left: 50%;
    margin-left: -28px;
    bottom: -30px;
}
.hosting_description .arrow_bottom {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 29px 28px 29px;
    border-color: transparent transparent #b00015 transparent;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -29px;
    bottom: -30px;
}
.hosting_description .curr .hosting_description_elem_bg {
    padding-bottom: 135px;
}
.hosting_description .curr .hosting_description_btn {
    height: 60px;
    line-height: 60px;
    font-size: 24px;
}

.hosting_description_price_blocks {
    background: #000000;
}
.hosting_description_price_block {
    padding: 20px 0;
    color: #fff;
    position: relative;
}

.hosting_description.bottom {

}
.hosting_description.bottom a.td {
    background: #b00015;
}
.hosting_description.bottom .hosting_description_elem_bg {
    padding: 0;
    background-color: transparent;
}
.hosting_description.bottom h5 {
    text-align: center;
    padding: 10px 20px 12px;
}
.hosting_description.bottom ul {
    list-style-type: none;
    text-align: center;
}

.box-banners {
    text-align:center;
    padding: 20px;
}

.page_note {
    border-left: 5px solid #b00015;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 10px 30px;
    margin: 0px 0 30px;
}
.page_note.last {
    margin-top: 30px;
}

.table_select {
    display: none;
}
.price_table.table {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
}
.price_table.sales {
    background: #fbf2f3;
}

/*--- FIX ---*/
.spacer {
    margin: 40px 0 60px;
}

.price_table .th,
.price_table .td {
    font-size: 14px;
    color: #000;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #d9d9d9;
    padding: 10px 5px;
}
.price_table .td {
    border-top: none;
}
.price_table .td.border_top {
    border-top: 1px solid #d9d9d9;
}
.price_table .th {
    background: #f3f3f3;
}
.price_table .name {
    width: 130px;
}
.sales_note {
    padding-top: 15px;
}
.price_table .name strong {
    font-weight: normal;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    background: #b00015;
    font-size: 12px;
    line-height: 24px;
    height: 24px;
}
.price_table .name strong a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 0 5px;
}
.price_table .name span {
    font-size: 14px;
    display: block;
    white-space: nowrap;
}
.price_table .name em {
    font-style: normal;
    color: #d11e14;
    display: none;
    font-size: 12px;
    line-height: 14px;
}
.price_table .name sup {
    line-height: 19px;
}
.price_table .cpu {

}
.price_table .ssd {
    swidth: 150px;
}
.price_table .checkbox.left p {
    white-space: nowrap;
    margin-left: 36px;
    padding-bottom: 0;
}
.price_table .ram {
    width: 60px;
}
.price_table .traffic {
    width: 90px;
}
.price_table .os {
    width: 100px;
}
.price_table .root {
    width: 70px;
}
.price_table .smart {
    width: 110px;
}
.price_table .smart em {
    font-style: normal;
    color: #b00015;
    cursor: pointer;
    border-bottom: 1px dotted #b00015;
}
.price_table .smart_icon {
    display: none;
    width: 16px;
    height: 16px;
    border: 2px solid #959595;
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: 0 auto;
    cursor: pointer;
}
.price_table .price {
    width: 230px;
}
.price_table.price1 .price {
    width: 300px;
}
.price_table .price_box {
    display: inline-block;
    vertical-align: middle;
    width: 115px;
}
.fs-17px {
 font-size: 17px;
}
.price_table .total_price {
    display: inline-block;
    width: 115px;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
    sfont-size: 20px;
    sline-height: 30px;
}
.price_table .total_price em {
    text-decoration: line-through;
    font-style: normal;
    color: #999999;
}
.price_table .total_price em strong {
    font-weight: normal;
}
.price_table.price1 .total_price {
    width: 145px;
}
.price_table .total_price span {
    sdisplay: block;
    sfont-size: 20px;
    sline-height: 30px;
    display: inline-block;
}
.price_table .nodiscount_price {
    width: 115px;
    display: inline-block;
    text-decoration: line-through;
}
.price_table .total_btn  {
    display: inline-block;
    background: #b00015;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}
.smart_about {
    overflow: hidden;
    display: none;
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    background: #fafafa;
}
.smart_about_close {
    position: absolute;
    z-index: 20;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    background: #C4122F url('../i/ic_close_white_48px.svg') 50% 50% no-repeat;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    cursor: pointer;
    -webkit-border-radius: 1005px;
    -moz-border-radius: 1005px;
    border-radius: 1005px;
}
.smart_about .td {
    text-align: left;
    padding: 15px 20px;
}

.order_table.table {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.order_table .th,
.order_table .td {
    font-size: 14px;
    color: #000;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #d9d9d9;
    padding: 15px 5px;
}
.wide .order_table .th,
.wide .order_table .td,
.order_form .order_table .th,
.order_form .order_table .td {
    padding: 0 5px;
    border: none;
    font-weight: normal;
    background-color: transparent;
}
.wide .order_table .th,
.order_form .order_table .th {
    padding-bottom: 15px;
}
.order_table .th.input,
.order_table .td.input {
    width: 40px;
    padding-left: 0;
}
.zone-price-noaction,
.wide .order_table .td .dprice-action,
.order_table .td .dprice-action {
    text-decoration:line-through;
/*    color: #000;*/
}
.wide > h4 {
    padding-bottom: 20px;
}
.wide .form-group + h6 {
    padding-top: 20px;
}
.wide .select {
    background: #fff;
}
.order_table .td {
    border-top: none;
}
.order_table .th {
    background: #f3f3f3;
    font-weight: bold;
}


.description  {
    background: #212121;
    padding: 20px 0 45px;
    color: #fff;
}
.description .description_title {
    text-align: center;
    padding-bottom: 33px;
    padding-top: 20px;
    font-size: 36px;
    line-height: normal;
}
.description .table {
    width: 100%;
    font-weight: 400;
}
.description h3 {
    padding: 25px 0 15px;
    position: relative;
    z-index: 2;
}
.description p {
    font-size: 15px;
    line-height: 20px;
    padding: 0 60px;
    position: relative;
    z-index: 2;
}
.description .block {
    padding: 0 15px 10px;
    text-align: center;
    position: relative;
    text-decoration: none;
    color: #fff;
}
.description .block_bg {
    height: 80px;
    margin: 0 auto;
}
.description .block1 .block_bg {
    width: 55px;
    background: url('../i/principle_security.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
}
.description .block2 .block_bg {
    width: 75px;
    background: url('../i/principle_support.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
}
.description .block3 .block_bg {
    width: 67px;
    background: url('../i/principle_speed.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
}
.description .block4 .block_bg {
    width: 68px;
    background: url('../i/principle_flex.svg') 50% 50% no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
}
.description .button {
    border-bottom: 1px solid #fff;
    color: #fff;
    text-decoration: none;
    stext-transform: uppercase;
    sfont-size: 12px;
    position: relative;
    z-index: 2;
    font-weight: 400;
}

.l {
    float: left;
    width: 50%;
    margin-right: -100%;
}
.r {
    margin-left: 50%;
}
.cols .l {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 25px;
}
.cols .r {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 25px;
}
.pay .l {
    width: 30%;
    border-right: 1px dotted #ccc;
}
.pay .r {
    margin-left: 35%;
}
.cols .cols_block {
    padding-left: 40px;
    display: block;
    text-decoration: none;
    color: #000;
}
.cols .cols_block.bg1 {
    background: url('../i/ic_contact_phone_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg2 {
    background: url('../i/ic_domain_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg3 {
    background: url('../i/ic_dvr_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg4 {
    background: url('../i/ic_extension_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg5 {
    background: url('../i/ic_backup_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg6 {
    background: url('../i/ic_storage_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg7 {
    background: url('../i/ic_email_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg8 {
    background: url('../i/ic_font_download_black_24px.svg') 0 13px no-repeat;
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.cols .cols_block.bg9 {
    background: url('../i/domain_.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg10 {
    background: url('../i/ip.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg11 {
    background: url('../i/unlim-bandwidth.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg12 {
    background: url('../i/smart-panel.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg13 {
    background: url('../i/backup.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg14 {
    background: url('../i/mail-server.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg15 {
    background: url('../i/mail-accounts.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg16 {
    background: url('../i/user-permisions.svg') 0 13px no-repeat;
    -webkit-background-size: 22px auto;
    background-size: 22px auto;
}
.cols .cols_block.bg17 {
    background: url('../i/support-finance.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}

.cols .cols_block.bg18 {
    background: url('../i/mib-infinite.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg19 {
    background: url('../i/mib-filemanager.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg20 {
    background: url('../i/mib-cms.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg21 {
    background: url('../i/mib-stat.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg22 {
    background: url('../i/mib-sql.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg23 {
    background: url('../i/mib-ftp.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg24 {
    background: url('../i/mib-cron.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg25 {
    background: url('../i/mib-access.svg') 0 13px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.cols .cols_block.bg26 {
    background: url('../i/mib-database.svg') 0 7px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}

.cols .cols_block.bg29 {
    background: url('../i/list.svg') 8px 14px no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}
.cols .cols_block.bg30 {
    background: url('../i/add.svg') 8px 14px no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}
.cols .cols_block.bg31 {
    background: url('../i/settings.svg') 8px 14px no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}
.cols .cols_block.bg32 {
    background: url('../i/idea.svg') 8px 14px no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}

.cols .cols_block.bg33 {
    background: url('../i/iso.svg') 0 13px no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}

.cols .cols_block.bg34 {
    background: url('../i/private-network.svg') 0 13px no-repeat;
    -webkit-background-size: 24px auto;
    background-size: 24px auto;
}

.cols .cols_block.bg35 {
    background: url('../i/outtraffic.svg') 0 13px no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}

.cols .cols_block.bg36 {
    background: url('../i/firewall.svg') 0 13px no-repeat;
    -webkit-background-size: 20px auto;
    background-size: 20px auto;
}

.contact {
}
.contact .address_lines {
    width: 385px;
    padding: 30px 20px;
}
.contact .form {
    margin-left: 425px;
    padding: 30px 70px;
    background: #F9F9F9; /* url('../i/ic_help_outline.svg') 20px 45px no-repeat; */
    -webkit-background-size: 30px auto;
    background-size: 30px auto;
}
.contact .form table.first {
    margin-top: 25px;
}
.contact .form td {
    padding-bottom: 15px;
}
.contact .form #form_prev_btn {
    float: left;
}
.address_line {
    border-bottom: 1px solid #ededed;
    padding-top: 20px;
    padding-bottom: 20px;
}
.address_line:first-child {
    padding-top: 0;
}
.address_line:last-child {
    border-bottom: none;
}
.address_line.address_icon {
    background: url('../i/ic_room_gray.svg') 20px 14px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.address_line.phone_icon {
    background: url('../i/ic_phone_gray.svg') 20px 35px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
}
.address_line.email_icon {
    background: url('../i/ic_email_gray.svg') 20px 32px no-repeat;
    -webkit-background-size: 32px auto;
    background-size: 32px auto;
    padding-top: 34px;
}
.address_line h3{
    padding-left: 70px;
    color: #b10005;
}
.address_line p{
    padding-left: 70px;
    padding-bottom: 5px;
}

.map {
    height: 500px;
    border-top: 10px solid #b00015;
    background: #787878;
}

.search_l {
    width: 260px;
    float: left;
    margin-right: -100%;
}
.search_r {
    margin-left: 260px;
}
.search_txt {
    float: left;
    width: 55px;
    margin-right: -100%;
}
.search_inputs {
    margin-left: 55px;
}
.search_field {
    float: left;
    width: 330px;
    margin-right: -100%;
    font-size: 14px;
}
.input_checkbox {
    vertical-align: top;
    position: relative;
    top: 13px;
}
.input_txt {
    width: 320px;
    height: 40px;
    box-sizing: border-box;
    margin: 0;
    padding: 0 10px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    vertical-align: top;
}
.search_button {
    margin-left: 330px;
}
.input_btn {
    width: 150px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    background: #01579b;
    border: none;
    vertical-align: top;
    cursor: pointer;
}
.tab_link_mirror {
    height: 26px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    display: none;
}
.tabs_container {
    position: relative;
    z-index: 2;
    padding-top: 30px;
}
.tabs_ {
    position: relative;
}
.tab_ {
    display: none;
}
.tab_.current {
    display: block;
}
.list {
    padding-bottom: 15px;
}
.list_elem {
    float: left;
    width: 16.5%;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: 400;
}
.list_elem input {
    margin-right: 10px;
    vertical-align: top;
    margin-top: 1px;
}
.tab_links {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
.tab_links_container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}
.tab_links_table {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.tab_links_table_row {
    display: table-row;
}
.tab_link {
    display: table-cell;
    vertical-align: middle;
    height: 39px;
    border-left: 1px solid #B00015;
    border-right: 1px solid #B00015;
    border-bottom: 1px solid #B00015;
    text-align: center;
    cursor: pointer;
    color: #B00015;
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
}
.tab_link.current {
    height: 40px;
    background: #b3e5fc;
    color: #000;
    cursor: auto;
}
.tab_links_table_sep {
    display: table-cell;
    width: 2px;
}


.calc_tab {
    padding-top: 30px;
}
.calc.tabs {
    height: 55px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
}
.calc .curr {
    height: 55px;
}
.calc {

}
.calc input {
    margin: 0;
}
.calc_status {
    position: relative;
    padding-top: 10px;
}
.calc_status p {
    line-height: 28px;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}
.calc_status_progress {
    height: 28px;
    border: 1px solid #b00015;
    position: relative;
}
.calc_status_progress_line {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 0;
    background: #b00015;
    white-space: nowrap;
    overflow: hidden;
}
.calc_status_progress_line p {
    color: #fff;
}

.calc_steps {
    position: relative;
    margin-top: 35px;
    overflow: hidden;
}
.calc_steps_line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 19px;
    width: 100%;
    height: 2px;
    background: #e2e2e2;
}
.calc_steps_list {
    width: 100%;
    position: relative;
    z-index: 1;
}
.calc_steps_list .td {
    position: relative;
    text-align: right;
}
.calc_steps_list .td:first-child {
    width: 38px;
}
.calc_steps_list .td:last-child {
}
.calc_steps_elem {
    display: inline-block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    background: #fff;
    border: 2px solid #e2e2e2;
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    color: #e2e2e2;
    position: relative;
    z-index: 2;
}

.calc_steps_clone {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
}
.calc_steps_clone .calc_steps_line {
    background: #b00015;
}
.calc_steps_clone .calc_steps_elem {
    border: 2px solid #b00015;
    color: #b00015;
}

.calc_points {
    padding: 20px 0;
}
.calc_points em {
    display: inline-block;
    font-style: normal;
    width: 220px;
    height: 36px;
    line-height: 36px;
}
.calc_point {
    height: 36px;
    line-height: 36px;
}
.calc_point.calc_point_curr {
    color: #b00015;
}

.calc_result {
    width: 100%;
    border: 1px solid #b00015;
}
.calc_result_header {
    background: #b00015;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    width: 20%;
}
.calc_result_txt {
    vertical-align: middle;
}
.calc_result_txt ul {
    list-style-type: none;
    margin: 0 20px;
    padding: 10px 0;
}
.calc_result_txt li {
    line-height: 22px;
}
.calc_result_price {
    width: 15%;
    background: #f3f3f3;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    vertical-align: middle;
}
.calc_result_price span {
    font-size: 40px;
    line-height: 40px;
    display: block;
    margin: 0 20px;
}
.calc_result_order {
    background: #f3f3f3;
    font-size: 0;
    vertical-align: middle;
    padding: 0 10px 0 0;
    width: 25%;
}
.calc_result_order_btns {
    display: inline-block;
    font-size: 13px;
    padding-left: 20px;
}
.calc_result_order .calc_result_order_btn {
    margin: 10px 0 10px;
    font-size: 12px;
}

.calc_future {
    width: 100%;
}
.calc_future_elem {
    padding: 0 !important;
    border: none !important;
    text-align: center;
    vertical-align: middle !important;
    height: 40px;
    background: url('../i/ic_check_circle_black_24px.svg') 50% 50% no-repeat;
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px;
    opacity: .5;
    cursor: pointer;
}
.calc_future_elem.curr {
    opacity: 1;
    cursor: auto;
}
.calc_future_elem.done {
    sbackground: url('../i/window33.svg') 50% 0 no-repeat, url('../i/ic_check_circle_black_24px.svg') 50% 100% no-repeat;
    s-webkit-background-size: 40px 40px, 30px 30px;
    sbackground-size: 40px 40px, 30px 30px;
}
.calc_future_list_elem {
    text-align: center;
    padding-top: 30px;
}

.calc_future_arrows {
    text-align: center;
    font-size: 0;
    padding-bottom: 20px;
}
.calc_future_arrows em {
    font-size: 16px;
    line-height: 40px;
    font-style: normal;
    margin: 0 20px;
    display: inline-block;
    vertical-align: top;
}
.calc_future_arrows_back {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: none;
    background: url('../i/ic_arrow_back_red.svg') 50% 50% no-repeat;
    -webkit-background-size: 34px 34px;
    background-size: 34px 34px;
    vertical-align: top;
    cursor: pointer;
    outline: none;
}
.calc_future_arrows_next {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: none;
    background: url('../i/ic_arrow_forward_red.svg') 50% 50% no-repeat;
    -webkit-background-size: 34px 34px;
    background-size: 34px 34px;
    vertical-align: top;
    cursor: pointer;
    outline: none;
}
.calc_future_arrows_back.disabled {
    background: url('../i/ic_arrow_back_gray.svg') 50% 50% no-repeat;
    -webkit-background-size: 34px 34px;
    background-size: 34px 34px;
    cursor: auto;
}
.calc_future_arrows_next.disabled {
    background: url('../i/ic_arrow_forward_gray.svg') 50% 50% no-repeat;
    -webkit-background-size: 34px 34px;
    background-size: 34px 34px;
    cursor: auto;
}

.calc_icons {
    text-align: center;
    padding: 30px 0;
}
.calc_icon {
    display: inline-block;
    width: 160px;
    height: 125px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;

}
.calc_icon.checked {

}
.calc_icon label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 90px;
    height: 35px;
    background: url('../i/ic_radio_button_unchecked.svg') 50% 60px no-repeat;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    opacity: 0.5;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
}
.calc_icon.checked label {
    background: url('../i/ic_radio_button_checked.svg') 50% 60px no-repeat;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    opacity: 1;
}
.calc_icon input {
    position: absolute;
    left: -10000px;
    top: 0;
}

.calc_icon.step1_1 {
    background: url('../i/ic_info_gray.svg') 50% 0 no-repeat;
    -webkit-background-size: 50px 50px;
    background-size: 50px 50px;
}
.calc_icon.step1_2 {
    background: url('../i/ic_person_gray.svg') 50% 0 no-repeat;
    -webkit-background-size: 50px 50px;
    background-size: 50px 50px;
}
.calc_icon.step1_3 {
    background: url('../i/ic_shopping_cart_gray.svg') 50% 0 no-repeat;
    -webkit-background-size: 50px 50px;
    background-size: 50px 50px;
}
.calc_icon.step1_4 {
    background: url('../i/ic_work_gray.svg') 50% 0 no-repeat;
    -webkit-background-size: 50px 50px;
    background-size: 50px 50px;
}
.calc_icon.step1_5 {
    background: url('../i/ic_help_gray.svg') 50% 0 no-repeat;
    -webkit-background-size: 50px 50px;
    background-size: 50px 50px;
}

.payments {
    font-size: 0;
    margin-bottom: 30px;
    text-align: center;
}
.payment {
    display: inline-table;
    vertical-align: top;
    height: 68px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.payment_block {
    display: block;
    height: 68px;
    margin-left: 3px;
    margin-right: 4px;
    margin-bottom: 7px;
}
.payment_table {
    display: table;
    height: 68px;
    border-collapse: collapse;
    table-layout: fixed;
}
.payment_td {
    display: table-cell;
    vertical-align: middle;
    border: 1px solid #cccccc;
}
.full .payment {
    width: 50%;
}
.full .payment_table {
    width: 100%;
}
.payment.ur .payment_td {
    background: url('../i/payment-icon-1.png') 50% 12px no-repeat;
    -webkit-background-size: auto 25%;
    background-size: auto 25%;
    color: #336600;
    padding-left: 30px;
    padding-right: 30px;
    vertical-align: bottom;
    text-align: center;
    padding-bottom: 6px;
    font-size: 11px;
}
.payment.visa .payment_td {
    background: url('../i/payment-icon-2.png') 50% 50% no-repeat;
    -webkit-background-size: 60% auto;
    background-size: 60% auto;
}
.payment.webmoney .payment_td {
    background: url('../i/payment-icon-4.png') 50% 50% no-repeat;
    -webkit-background-size: 60% auto;
    background-size: 60% auto;
}
.payment.paypal .payment_td {
    background: url('../i/payment-icon-3.png') 50% 50% no-repeat;
    -webkit-background-size: 45% auto;
    background-size: 45% auto;
}
.payment.privat .payment_td {
    background: url('../i/payment-icon-6.png') 50% 50% no-repeat;
    -webkit-background-size: 60% auto;
    background-size: 60% auto;
}
.payment.bank .payment_td {
 background: url('../i/payment-icon-5.png') 50% 8px no-repeat;
    -webkit-background-size: auto 35%;
    background-size: auto 35%;
    color: #336600;
    padding-left: 5px;
    font-size: 11px;
    padding-right: 5px;
    vertical-align: bottom;
    padding-bottom: 6px;
    text-align: center;
}
.payments .payment:hover .payment_td {
    sbackground-color: #d9d9d9;
}

.reviews > .l {
    width: 100%;
    position: relative;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 400px;
}
.reviews > .r {
    width: 320px;
    float: right;
    position: relative;
    z-index: 2;
    margin-left: 0;
}
.review {
    padding-bottom: 30px;
}
.stars {
    overflow: hidden;
    font-size: 0;
    text-align: center;
}
.stars.rating {
    text-align: left;
}
.star {
    display: inline-block;
    background: url('../i/ic_star_rate_gray.svg') 50% 50% no-repeat;
}
.star.active {
    background: url('../i/ic_star_rate_yellow.svg') 50% 50% no-repeat;
}
.select_stars .star {
    width: 40px;
    height: 40px;
    display: inline-block;
    -webkit-background-size: 50px 50px;
    background-size: 50px 50px;
    cursor: pointer;
}
.rating .star {
    width: 20px;
    height: 20px;
    display: inline-block;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    vertical-align: top;
}
.rating .star_site {
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

.reasons {
    position: relative;
    padding-top: 60px;
}
.reasons .l,
.reasons .r{
    position: relative;
    z-index: 2;
}
.reasons .l{
    clear: left;
}
.reasons .r{
    float: right;
    width: 50%;
    clear: right;
}
.reasons .first {
    padding-top: 95px;
}
.reasons_center_line {
    position: absolute;
    z-index: 1;
    width: 60px;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -30px;
}
.reasons_center_line_dotted {
    position: absolute;
    z-index: 1;
    width: 28px;
    height: 100%;
    border-right: 3px dotted #787878;
}
.reasons_center_line_top {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 60px;
    background: #fff;
    left: 0;
    top: 0;
}
.reasons_center_line_bottom {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 305px;
    background: #fff;
    left: 0;
    bottom: 0;
}
.reason {
    height: 440px;
    position: relative;
    z-index: 3;
    margin-bottom: 40px;
}
.reason h3,
.reason p {
    width: 320px;
}
.reason_icon_block .table {
    width: 100%;
}
.reason_icon {
    width: 275px;
    padding-bottom: 30px;
    border-bottom: 1px dotted #b00015;
}
.reason_icon span {
    display: block;
    width: 120px;
    height: 120px;
    background: #787878;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: -40px auto 0;
}
.reason_line {
    position: relative;
}
.l .reason {
    margin-left: 50px;
}
.l .reason_num {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 100%;
    width: 54px;
    height: 54px;
    border: 3px solid #b00015;
    color: #b00015;
    text-align: center;
    line-height: 54px;
    font-size: 35px;
    background: #fff;
    margin-left: -30px;
}
.l .reason_line span {
    border-bottom: 1px dotted #b00015;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;

    -ms-transform: skewY(-26deg); /* IE 9 */
    -webkit-transform: skewY(-26deg); /* Safari */
    transform: skewY(-26deg);

    -ms-transform-origin: left bottom;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}
.r .reason {
}
.r .reason_num {
    position: absolute;
    z-index: 3;
    top: 145px;
    left: -30px;
    width: 54px;
    height: 54px;
    border: 3px solid #b00015;
    color: #b00015;
    text-align: center;
    line-height: 54px;
    font-size: 35px;
    background: #fff;
}
.r .reason_line span {
    border-bottom: 1px dotted #b00015;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;

    -ms-transform: skewY(-20deg); /* IE 9 */
    -webkit-transform: skewY(-20deg); /* Safari */
    transform: skewY(-20deg);

    -ms-transform-origin: right bottom;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
}
.r .reason_icon_block {
    margin-right: 30px;
}
.r .reason h3,
.r .reason p {
    float: right;
    clear: both;
}
.reason_icon.icon_1 span {
    background-image: url('../i/star.svg');
    background-position: 50% 21px;
    background-repeat: no-repeat;
    -webkit-background-size: 75px auto;
    background-size: 75px auto;
}
.reason_icon.icon_2 span {
    background-image: url('../i/data-copy.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 70px auto;
    background-size: 70px auto;
}
.reason_icon.icon_3 span {
    background-image: url('../i/support.svg');
    background-position:50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 70px auto;
    background-size: 70px auto;
}
.reason_icon.icon_4 span {
    background-image: url('../i/rocket.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 60px auto;
    background-size: 60px auto;
}
.reason_icon.icon_5 span {
    background-image: url('../i/control.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 60px auto;
    background-size: 60px auto;
}
.reason_icon.icon_6 span {
    background-image: url('../i/domain.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 70px auto;
    background-size: 70px auto;
}
.reason_icon.icon_7 span {
    background-image: url('../i/servers.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 60px auto;
    background-size: 60px auto;
}
.reason_icon.icon_8 span {
    background-image: url('../i/smart.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 60px auto;
    background-size: 60px auto;
}
.reason_icon.icon_9 span {
    background-image: url('../i/mail.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 60px auto;
    background-size: 60px auto;
}
.reason_icon.icon_10 span {
    background-image: url('../i/bug.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: 60px auto;
    background-size: 60px auto;
}

.form_icon {
    background-color: #B00015;
    float: left;
    height: 40px;
    width: 42px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
}

.vat-container.error,
.required .form_icon,
.error .form_icon {
    position: relative;
}

.required .form_icon:before {
    content: "*";
    position: absolute;
    right: 4px;
    top: 11px;
    color: #ffffff;
    line-height: 0;
}

.vat-container.error:after,
.error .form_icon:after {
    content: "!";
    font-weight: bold;
    font-size: 26px;
    position: absolute;
    width: 20px;
    height: 40px;
    line-height: 40px;
    left: -20px;
    top: 0;
    color: #B00015;
}
.form_phone_span {
    background-image: url('../i/ic_phone_white.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_sms_span {
    background-image: url('../i/ic_perm_phone_msg_white_24px.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_address_span {
    background-image: url('../i/ic_domain_white.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_name_span {
    background-image: url('../i/ic_person_black_24px.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_password_span {
    background-image: url('../i/ic_lock_white_24px.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_email_span {
    background-image: url('../i/ic_email_white.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_cat_span {
    background-image: url('../i/ic_assignment_black_24px.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_domain_span {
    background-image: url('../i/earth208.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_subject_span {
    background-image: url('../i/ic_subject_black_24px.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_code_span {
    background-image: url('../i/code.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_business_span {
    background-image: url('../i/business_name.svg');
    background-position: 12px 8px;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_code_span {
    background-image: url('../i/code_name.svg');
    background-position: 13px 7px;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_cube_span {
    background-image: url('../i/cube.svg');
    background-position: 13px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_passport_span {
    background-image: url('../i/ru_passport.svg');
    background-position: 12px 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_calendar_span {
    background-image: url('../i/ru_calendar.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}
.form_text_span {
    height: 140px;
    background-image: url('../i/ic_textsms_black_24px.svg');
    background-position: 10px 9px;
    background-repeat: no-repeat;
    -webkit-background-size: auto 20px;
    background-size: auto 20px;
}

.capcha {
}

.accordion {
    padding-bottom: 5px;
}
.accordion p:last-child {
    padding-bottom: 0;
}
.accordion h3 {
    padding-top: 0;
    outline: none;
}
.accordion span {
    cursor: pointer;
    border-bottom: 1px dotted #b00015;
    color: #b00015;
    font-size: 16px;
    font-weight: 400;
}
.accordion .ui-accordion-content {
    padding: 20px;
    background: #ededed;
}

.enter_form {
    padding: 30px 70px;
    background: #ededed; /* url('../i/ic_help_outline.svg') 20px 45px no-repeat; */
    max-width: 450px;
    margin: 0 auto;
}
.enter_form_icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}
.enter_form_icon.done {
    background: url('../i/ic_done_black_24px.svg') 50% 50% no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_form_icon.fail {
    background: url('../i/ic_info_outline_red.svg') 50% 50% no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.enter_form .select {
    display: inline-block;
    width: 200px;
    margin-top: 10px;
}
.enter_form .select .select_val {
    text-align: left;
    padding-left: 15px;
}
.enter_form .select .select_val {
    text-align: left;
    padding-left: 15px;
}
.enter_form .select.full_select {
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    background: #fff;
}
.enter_form .select.full_select .select_val {
    text-align: left;
    padding-left: 5px;
}
.enter_form.no_bg {
    background: transparent;
}
.enter_form .checkbox.left {
    float: left;
    line-height: 25px;
}
.enter_form .checkbox.left .icon {
    background-position: 0 50%;
    height: 25px;
}
.enter_form .checkbox.left p {
    margin-left: 30px;
    padding: 0;
    line-height: 25px;
}
.enter_form a {
    clear: both;
}
.enter_form h3 {
    text-align: center;
    padding-bottom: 20px;
}
.enter_form .accordion h4 {
    padding: 0;
    margin: 0;
    outline: none;
}
.enter_form .mdl {
    line-height: 50px;
}
.enter_form input[type="submit"] {
    width: 100%;
    margin-bottom: 20px;
}
.enter_form .phone_cell .ovh {
    margin-top: -10px;
    margin-bottom: 15px;
}

.error,
.warning {
    color: #B00015;
    line-height: 20px;
    margin-bottom: 15px;
    font-size: 13px;
    margin-top: -10px;
    text-align: center;
}
.part-notice,
.notice,
.confirmation,
.loading {
    line-height: 20px;
    margin-bottom: 15px;
    font-size: 13px;
    margin-top: -10px;
    text-align: center;
}
.enter_form td .error {
    margin-right: 42px;
}
#add_phone_row {
    text-align: center;
}
#add_phone_row a {
    margin-bottom: 15px;
    display: inline-block;
}

.images {
    font-size: 0;
    vertical-align: top;
    text-align: center;
    padding: 20px 0 50px;
}
.images img {
    margin: 0 30px;
    cursor: pointer;
}

.documents {
    list-style-type: none;
    margin-left: 0;
}
.icon_doc {
    width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: top;
    background: url('../i/doc.svg') 0 0 no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    margin-right: 5px;
}
.icon_pdf {
    width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: top;
    background: url('../i/pdf.svg') 0 0 no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    margin-right: 5px;
}


#mirohost-buttons {

}
#mirohost-buttons .btn {
    font-weight: 300;
}
#mirohost-buttons textarea {
    font-size: 11px;
}
.mirohost-buttons-list {
    overflow: hidden;
    width: 400px;
    padding-left: 10px;
}
.mirohost-buttons-list img {
    float: left;
    margin-left: 6px;
    margin-bottom: 16px;
    border: 2px solid #fff;
    cursor: pointer;
}
.mirohost-buttons-list img.curr {
    border: 2px solid #b00015;
}
/* Content */

/* Error 404 */
.e404 {
    background: #000;
    color: #929497;
}
.e404 header {
    background: #fff;
}
.e404 h1 {
    font-size: 200px;
    font-weight: 400;
    color: #c4122f;
}
.e404 p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}
.e404 a {
    text-decoration: none;
}
.e404 .square_container {
    margin-left: 100px;
    padding-top: 70px;
}
.e404 .square {
    border-color: #000;
}
/* Error 404 */

/* Footer */
.footer_spacer {
    position: relative;
    z-index: 1;
    height: 175px;
}
footer {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 175px;
    background: #fff;
}
.footer_top {
    height: 85px;
    background: #000;
    color: #929497;
    font-weight: 400;
    font-size: 12px;
    overflow: hidden;
    -webkit-box-shadow:  0 1px 5px rgba(24,24,24,.25);
    -moz-box-shadow:  0 1px 5px rgba(24,24,24,.25);
    box-shadow:  0 1px 5px rgba(24,24,24,.25);
}
.footer_nav_elem {
    overflow: hidden;
    position: relative;
    stop: -10px;
    margin-bottom: -7px;
}
.footer_nav_elem h4 {
    line-height: 15px;
    font-size: 13px;
    float: left;
    margin-right: 10px;
    color: #cccccc;
    padding: 0;
}
.footer_nav_elem ul {
    list-style-type: none;
    margin: 0;
    height: 50px;
    text-align: center;
    overflow: hidden;
}
.footer_nav_elem li {
    display: inline-block;
    margin-left: 9px;
    padding-right: 9px;
    line-height: 50px;
    color: #464646;
}
.footer_nav_elem li:first-child {
    margin-left: 0;
}
.footer_nav_elem li:last-child {
    border: none;
}
.footer_nav_elem a {
    color: #929497;
    text-decoration: none;
}
.footer_logos {
    height: 90px;
    max-width: 1090px;
    margin: 0 auto;
}
.footer_logos_table {
    display: table;
    height: 90px;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}
.footer_logos_table div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.footer_logos img {
    vertical-align: top;
}
.copy {
    padding-top: 5px;
    text-align: center;
}
/* Footer */


/* Other */
.checkbox {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    /*display: block;*/
}
.checkbox input {
    position: absolute;
    left: -50000px;
}
.checkbox .icon {
    position: relative;
    overflow: hidden;
    height: 34px;
    width: 34px;
    margin: 0 auto;
    /* background: url('../i/ic_check_box_outline_blank.svg') 50% 50% no-repeat; */
}
.checkbox .icon:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 7px;
    border: 2px solid #515151;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.checkbox.checked .icon {
    background: url('../i/ic_check_box.svg') 50% 50% no-repeat;
    -webkit-background-size: 12px auto;
    background-size: 12px auto;
}
.checkbox.checked .icon:after {
    border: 2px solid #b00015;
}

.radio {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: block;
}
.radio > input {
    position: absolute;
    left: -1000px;
}
.radio > .icon {
    position: relative;
    overflow: hidden;
    height: 34px;
    width: 34px;
    margin: 0 auto;
    background: url('../i/ic_radio_button_unchecked.svg') 50% 50% no-repeat;
}
.radio.checked > .icon {
    background: url('../i/ic_radio_button_checked.svg') 50% 50% no-repeat;
}
.radio.inline {
    line-height: 34px;
}
.radio.inline .icon {
    float: left;
    margin: 0;
}

.radio.left,
.checkbox.left {
    display: block;
    float: none;
    text-align: left;
    line-height: normal;
}
.radio.left > .icon,
.checkbox.left > .icon {
    margin-left: 0;
    margin-right: -100%;
    margin-top: 10px;
    float: left;
}
.wide .radio.left > .icon,
.wide .checkbox.left > .icon {
    margin-top: 0;
}
.checkbox.left .icon {
    margin-top: 0;
}
.radio.left h3,
.checkbox.left h3,
.radio.left h4,
.checkbox.left h4,
.radio.left h5,
.checkbox.left h5,
.radio.left p,
.checkbox.left p,
.radio.left .txt,
.checkbox.left .txt {
    margin-left: 50px;
    text-align: left;
}
.radio.left h4,
.checkbox.left h4,
.radio.left h5,
.checkbox.left h5 {
    padding-top: 0;
}
.radio.radio-phone h4 {
    line-height: 34px;
}
.radio.radio-phone .icon {
    margin-top: 0;
}
.checkbox.left p {
    line-height: 34px;
}
.wide .checkbox.left p {
    line-height: 26px;
    padding-top: 3px;
    margin-left: 35px;
}
.vat-container .radio.left {
    float: left;
    margin-right: 35px;
}
.vat-container .radio.left p {
    line-height: 34px;
    margin-left: 34px;
    padding-bottom: 0;
}

.mt20 {
    margin-top: 20px;
}
.pb20 {
    padding-bottom: 20px;
}
.pw10 {
    padding-left: 10px;
    padding-right: 10px;
}
.fll {
    float: left;
}
.flr {
    float: right;
}
.clear {
    clear: both;
}
.tac {
    text-align: center;
}
.tal {
    text-align: left;
}
.ovh {
    overflow: hidden;
}
.table {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
}
.full {
    width: 100%;
}
.tr {
    display: table-row;
}
.th {
    display: table-cell;
}
.td {
    display: table-cell;
}
.full.table {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}
table.half,
.half.table {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 600px;
}
.border_table .th,
.border_table .td,
.border_table th,
.border_table td {
    font-size: 14px;
    color: #000;
    vertical-align: middle;
    text-align: left;
    border: 1px solid #d9d9d9;
    padding: 10px;
}
.border_table .th,
.border_table th {
    background: #f3f3f3;
}
.text_table td {
    padding: 10px;
}
.border_table.bottom_indent {
    margin-bottom: 20px;
}
.text_table .small,
.border_table .small {
    width: 110px;
}
.separator {
    width: 15px;
}
.hidden {
    display: none;
}
.c {
    position: relative;
    float: right;
    right: 50%;
}
.cc {
    position: relative;
    float: left;
    left: 50%;
}
.layout .no_rb {
    border-right: none;
}

.btn {
    height: 40px;
    line-height: 40px;
    background: #B00015;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    display: block;
    padding: 0 25px;
}

.tabs {
    display: table;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-bottom: 1px solid #e2e2e2;
}
.tab {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-bottom: none;
    cursor: pointer;
}
.tab.curr {
    cursor: auto;
    border-bottom: 3px solid #b00015;
}
.tab span {
    color: #707070;
}
.tab.curr span {
    color: #b00015;
    border: none;
}

a.pdf {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    background: url('../i/pdf.svg') 50% 50% no-repeat;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    margin-bottom: 5px;
}
a.doc {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    background: url('../i/word.svg') 50% 50% no-repeat;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    margin-bottom: 10px;
}
/* Other */





/* eVPS Plasm */
.tariffs {
    padding: 10px 0 50px;
    background: #000;
    overflow: hidden;
    color: #929497;
}
.tariffs_about {
    float: left;
    width: 50%;
    padding-top: 40px;
}
.tariffs_hint {
    width: 20px;
    height: 20px;
    background: #212121 url('../i/ic_close_white_48px.svg') 150px 0 no-repeat;
    color: #fff;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    top: 135px;
    left: 161px;
    cursor: pointer;
}
.tariffs_hint.open {
    background: #212121 url('../i/ic_close_white_48px.svg') 50% 50% no-repeat;
    -webkit-background-size: 16px 16px;
    background-size: 16px 16px;
    overflow: hidden;
    text-indent: 100px;
}
.tariffs_hint_text {
    display: none;
    padding-right: 50px;
    clear: left;
}
.tariffs_hint_text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

}
.tariffs_header {
    color: #C4122F;
    font-size: 60px;
    font-weight: 400;
    float: left;
    padding: 0;
}
.tariffs_note {
    font-size: 20px;
    font-weight: 400;
    width: 270px;
}
.tariffs_note span {
    padding-top: 10px;
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    display: inline-block;
}
.tariffs_order {
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 20px;
}
.tariffs_price {
    float: left;
    margin-right: -100%;
    height: 60px;
    text-align: center;
}
.tariffs_price span {
    font-size: 40px;
    display: block;
    color: #C4122F;
    line-height: 40px;
}
.tariffs_btn {
    float: left;
    margin-left: 110px;
    height: 60px;
    line-height: 60px;
    background: #C4122F;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 40px;
    -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1);
    -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1);
    box-shadow: 0 0 10px rgba(209, 30, 20, 1);
    font-size: 24px;
    letter-spacing: 1px;
}
.square_container {
    height: 400px;
    position: relative;
    background: #000;
    float: left;
    width: 50%;
    min-width: 280px;
}
.all_square {
    position: relative;
    z-index: 1;
    width: 160px;
    height: 160px;
    -webkit-transform: rotateY(30deg);
    transform: rotateY(30deg);
    top: 120px;
    left: 0px;
}
.square {
    overflow: hidden;
    background: #000;
    width: 160px;
    position: absolute;
}
.square_1 {
    left: -18px;
    top: 0;
    -webkit-transform: skewY(23deg) rotateY(10deg);
    -ms-transform: skewY(23deg) rotateY(10deg);
    transform: skewY(23deg) rotateY(10deg);
    border-top: 2px solid #C4122F;
    padding-left: 20px;
    padding-bottom: 20px;
}
.square_2 {
    left: 159px;
    top: 0;
    -webkit-transform: skewY(-23deg) rotateY(10deg);
    -ms-transform: skewY(-23deg) rotateY(10deg);
    transform: skewY(-23deg) rotateY(10deg);
    border-left: 2px solid #C4122F;
    border-top: 2px solid #C4122F;
    padding-right: 20px;
    padding-bottom: 20px;
}
.square_3 {
    left: 80px;
    bottom: 114px;
    -webkit-transform: rotate(45deg) rotateZ(0deg) skew(-22deg, -22deg);
    -ms-transform: rotate(45deg) rotateZ(0deg) skew(-22deg, -22deg);
    transform: rotate(45deg) rotateZ(0deg) skew(-22deg, -22deg);
}
.square_small {
    float: left;
    margin: 8px;
    width: 24px;
    height: 24px;
    background: #231F20;
    -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
    -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
    box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #303030;

    font-size: 0;
    sline-height: 0;
}
.square_small.before_light {
    -webkit-animation: pulsar-start 700ms 0ms ease-out 1;
    -moz-animation: pulsar-start 700ms 0ms ease-out 1;
    -ms-animation: pulsar-start 700ms 0ms ease-out 1;
    -o-animation: pulsar-start 700ms 0ms ease-out 1;
    animation: pulsar-start 700ms 0ms ease-out 1;

    border: none;
    -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    background: #C4122F;
}
.square_small.after_light {
    -webkit-animation: pulsar-after 700ms 0ms ease-out 1;
    -moz-animation: pulsar-after 700ms 0ms ease-out 1;
    -ms-animation: pulsar-after 700ms 0ms ease-out 1;
    -o-animation: pulsar-after 700ms 0ms ease-out 1;
    animation: pulsar-after 700ms 0ms ease-out 1;

    border: none;
    background: #231F20;
    -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
    -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
    box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
}
.square_small.light {
    border: 1px solid #C4122F;
    background: #C4122F;
    -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);

    -webkit-animation: pulsar 2500ms 0ms ease-out infinite;
    -moz-animation: pulsar 2500ms 0ms ease-out infinite;
    -ms-animation: pulsar 2500ms 0ms ease-out infinite;
    -o-animation: pulsar 2500ms 0ms ease-out infinite;
    animation: pulsar 2500ms 0ms ease-out infinite;
}
@-webkit-keyframes pulsar {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    50% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@-moz-keyframes pulsar {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    50% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@-ms-keyframes pulsar {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    50% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@-o-keyframes pulsar {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    50% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@keyframes pulsar {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    50% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@-webkit-keyframes pulsar-start {
    0% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
}
@-moz-keyframes pulsar-start {
    0% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
}
@-ms-keyframes pulsar-start {
    0% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
}
@-o-keyframes pulsar-start {
    0% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
}
@keyframes pulsar-start {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #231F20;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
}
@-webkit-keyframes pulsar-after {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
    100% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
}
@-moz-keyframes pulsar-after {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
    100% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
}
@-ms-keyframes pulsar-after {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
    100% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
}
@-o-keyframes pulsar-after {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
    100% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
}
@keyframes pulsar-after {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        background: #C4122F;
    }
    100% {
        -webkit-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        -moz-box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        box-shadow: 0 0 5px rgba(118, 118, 118, 0.8);
        background: #231F20;
    }
}
.tariff_data_line {
    position: absolute;
    z-index: 2;
    height: 1px;
    border-top: 2px solid #C4122F;
}
.tariff_data_line_1 {
    width: 80px;
    left: -84px;
    top: 82px;
}
.tariff_data_line_2 {
    width: 80px;
    left: 304px;
    top: 82px;
}
.tariff_data_line_3 {
    width: 100px;
    left: 150px;
    top: 360px;
    -webkit-transform: skewY(23deg);
    -ms-transform: skewY(23deg);
    transform: skewY(23deg);
}
.tariff_data {
    position: absolute;
    z-index: 3;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
}
.tariff_data span {
    display: block;
    color: #C4122F;
    font-size: 36px;
    white-space: nowrap;
}
.tariff_data_1 {
    top: 0;
    left: -110px;
}
.tariff_data_2 {
    top: 0;
    left: 304px;
}
.tariff_data_3 {
    top: 320px;
    left: 264px;
}
.tariffs_bottom_text {
    clear: both;
    font-size: 20px;
    line-height: 60px;
    display: inline-block;
    text-decoration: none;
    color: #929497;
    margin-bottom: 20px;
}
.tariffs_bottom_text:last-child {
    margin-bottom: 0;
    margin-left: 150px;
}
.tariffs_bottom_text span {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border: 1px solid #929497;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-size: 12px;
}
.tariffs_bottom_text em {
    font-style: normal;
    border-bottom: 1px solid #929497;
    font-weight: 400;
}
.tariffs_list {
    list-style-type: none;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin:  0 0 30px;
}
.tariffs_list li {
    display: table-cell;
    height: 62px;
    position: relative;
    vertical-align: top;
    color: #C4122F;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}
.tariffs_list li span {
    display: inline-block;
    margin-top: 7px;
}
.tariffs_list li a {
    display: block;
    height: 40px;
    line-height: 40px;
    color: #C4122F;
    text-decoration: none;
}
.tariffs_list .curr,
.tariffs_list .curr a {
    color: #fff;
    background: #212121;
    text-decoration: none;
}

.tariffs_list.on_evps .curr {
    color: #fff;
    background: #212121;
    cursor: default;
}

.square_small.light404 {
    border: 1px solid #C4122F;
    background: #C4122F;
    -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);

    -webkit-animation: light404 2500ms 0ms ease-out infinite;
    -moz-animation: light404 2500ms 0ms ease-out infinite;
    -ms-animation: light404 2500ms 0ms ease-out infinite;
    -o-animation: light404 2500ms 0ms ease-out infinite;
    animation: light404 2500ms 0ms ease-out infinite;
}
@-webkit-keyframes light404 {
    0% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    20% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    21% {
        opacity: .1;
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    23% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    28% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    30% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    32% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    34% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        opacity: 1;
    }
    50% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    60% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    62% {
        opacity: .1;
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    64% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    70% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    72% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    73% {
        opacity: 1;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@-moz-keyframes light404 {
    0% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    20% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    21% {
        opacity: .1;
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    23% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    28% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    30% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    32% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    34% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        opacity: 1;
    }
    50% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    60% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    62% {
        opacity: .1;
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    64% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    70% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    72% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    73% {
        opacity: 1;
    }
    100% {
        -moz-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@-ms-keyframes light404 {
    0% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    20% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    21% {
        opacity: .1;
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    23% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    28% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    30% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    32% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    34% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        opacity: 1;
    }
    50% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    60% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    62% {
        opacity: .1;
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    64% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    70% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    72% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    73% {
        opacity: 1;
    }
    100% {
        -ms-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@-o-keyframes light404 {
    0% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    20% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    21% {
        opacity: .1;
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    23% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    28% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    30% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    32% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    34% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        opacity: 1;
    }
    50% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    60% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    62% {
        opacity: .1;
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    64% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    70% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    72% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    73% {
        opacity: 1;
    }
    100% {
        -o-box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
@keyframes light404 {
    0% {
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    20% {
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    21% {
        opacity: .1;
        box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    23% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    28% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    30% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    32% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    34% {
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
        opacity: 1;
    }
    50% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
    }
    60% {
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
    62% {
        opacity: .1;
        box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
    }
    64% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    70% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1), 0 0 10px rgba(209, 30, 20, .1);
        opacity: .1;
    }
    72% {
        box-shadow: 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5), 0 0 10px rgba(209, 30, 20, .5);
        opacity: .5;
    }
    73% {
        opacity: 1;
    }
    100% {
        box-shadow: 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1), 0 0 10px rgba(209, 30, 20, 1);
    }
}
/* eVPS Plasm */




/*
@media screen and (min-width: 1600px) {
    body {
        font-size: 16px;
    }
    h1 {
        font-size: 38px;
    }
    h2 {
        font-size: 32px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    h6,
    input[type="text"],
    input[type="email"],
    input[type="password"],
   mirohost-buttons input[type="num"],
    input[type="tel"],
    input[type="submit"],
    .logo_txt,
    .select,
    select,
    textarea {
        font-size: 16px;
    }
    .header_btns {
        font-size: 14px;
    }
    .logo_txt {
         padding-top: 0;
    }
    .slider a.btn,
    .header_contacts {
        font-size: 22px;
    }
    .header_contacts span {
        font-size: 16px;
    }
    .tariffs_note,
    .slider .info li:before,
    nav .menu_nav_close {
        font-size: 22px;
    }
    .tariffs_note span,
    .tariffs_hint_text p,
    .tariffs_hint,
    .footer_nav_elem li,
    .form_icon,
    .tab_link,
    .input_txt,
    .search_field,
    .order_table .th,
    .order_table .td,
    .price_table .total_btn,
    .price_table .total_price,
    .price_table .name span,
    .page_note,
    .hosting_description_price,
    .hosting_description p,
    .slider .info ul,
    nav .enter_btn,
    nav li,
    .slider ol,
    .slider ul,
    .slider .red_btn,
    .slider .info {
        font-size: 16px;
    }
    .price_table .th,
    .price_table .td {
        font-size: 16px;
    }
    .price_table .smart {
        width: 70px;
    }
    .price_table .traffic {
        width: 110px;
    }
    .price_table .os {
        width: 130px;
    }
    .tariffs_list li,
    .btn,
    .accordion span,
    .rating .star_site,
    .calc_future_arrows em {
        font-size: 18px;
    }
    .calc_result_order .calc_result_order_btn,
    .list_elem,
    .price_table .name em,
    .enter_block .enter_block_black a {
        font-size: 14px;
    }
    .tariffs_bottom_text span {
        font-size: 13px;
    }
    .tariffs_bottom_text,
    .tariff_data,
    .e404 p,
    .hosting_description_btn,
    .order_steps_total_next,
    .enter_block_close {
        font-size: 22px;
    }
    .slider h2 span {
        font-size: 32px;
    }
    .calc.tabs,
    .tab_link_mirror,
    .input_btn,
    .slider.min .note {
        font-size: 20px;
    }
    .tariffs_price span,
    .calc_result_price span,
    .calc_result_header,
    .hosting_description_price span {
        font-size: 42px;
    }
    .tariffs_btn,
    .hosting_description .curr .hosting_description_btn {
        font-size: 26px;
    }
    .price_table .smart_icon {
        font-size: 13px;
    }
    .calc_icon label,
    .calc_result_price,
    .description p {
        font-size: 17px;
    }
    .calc_steps_elem {
        font-size: 27px;
    }
    .footer_nav_elem h4,
    .calc_result_order_btns {
        font-size: 15px;
    }
    .part-notice,
    .notice,
    .confirmation,
    .loading,
    .error,
    .warning,
    .payment {
        font-size: 15px;
    }
    .r .reason_num,
    .l .reason_num {
        font-size: 37px;
    }
    .error .form_icon:after {
        font-size: 28px;
    }
    .tariff_data span {
        font-size: 38px;
    }
    .footer_nav_elem {
        overflow: visible;
    }
}

*/





@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes pulse {
    50% {
        background: white;
    }
}
@keyframes pulse {
    50% {
        background: white;
    }
}
.loading {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 0.25rem solid rgba(0, 0, 0, 0.2);
    border-top-color: rgba(0, 0, 0, 0.75);
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
}
.loading--double {
    border-style: double;
    border-width: .5rem;
}

.loading-pulse {
    position: relative;
    width: 6px;
    height: 24px;
    background: rgba(0, 0, 0, 0.2);
    -webkit-animation: pulse 750ms infinite;
    animation: pulse 750ms infinite;
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;
}
.loading-pulse:before, .loading-pulse:after {
    content: '';
    position: absolute;
    display: block;
    height: 16px;
    width: 6px;
    background: rgba(255, 255, 255, 0.2);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: pulse 750ms infinite;
    animation: pulse 750ms infinite;
}
.loading-pulse:before {
    left: -12px;
}
.loading-pulse:after {
    left: 12px;
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}
button .loading,
.loader .loading,
.loader .loading--double,
.loader .loading-pulse {
    position: absolute;
    left: 13px;
    top: 17px;
    height: 16px !important;
    width: 16px !important;
}
button .loading {
    position: relative;
    display: inline-block;
    vertical-align: top;
    top: 16px;
    left: 0;
    margin-right: 10px;
}
.loader.btn {
    padding-left: 50px;
}




@-webkit-keyframes page-redirect {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
@-moz-keyframes page-redirect {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
@-o-keyframes page-redirect {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
@keyframes page-redirect {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
.redirect_popup {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
}
.redirect_popup_reducer {
    position: relative;
    margin: 0 auto;
    max-width: 640px;
    padding: 0 20px;
}
.redirect_popup_bg {
    background: #fff;
}
.redirect_popup_title {
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    color: #000;
    background: #ececec;
    padding: 0 20px;
}
.redirect_popup_text {
    padding: 10px 20px;
}
.redirect_popup_loader {
    height: 5px;
    background: #03a9f4;
    width: 0;
    margin-top: 5px;
}
.redirect_popup_loader.start {
    -webkit-animation: page-redirect 3s linear 1;
    -moz-animation: page-redirect 3s linear 1;
    -o-animation: page-redirect 3s linear 1;
    animation: page-redirect 3s linear 1;
    width: 100%;
}
.redirect_popup_btn {
    padding: 10px 20px;
}
.redirect_popup_btn_indent {
    width: 20px;
    height: 40px;
}
.redirect_popup_btn_ok.btn {
    color: #000;
    background: #ececec;
}
.redirect_popup_btn_cancel {
    border: 1px solid #03a9f4;
    color: #03a9f4;
    background: #ececec;
}
.fade_,
.fade {
    background: rgba(0,0,0,.7);
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.d-inline-block {
    display: inline-block !important;
}

.hr {
/*    margin: 20px 0;*/
    width: 100%;
    height: 15px;
}

@media screen and (max-width: 1300px) {
    nav li {
        margin-right: 45px;
    }
}
@media screen and (max-width: 1250px) {

}
@media screen and (max-width: 1200px) {
    .tariffs_bottom_text:last-child {
        margin-bottom: 0;
        margin-left: 0;
        display: block;
    }
    .price_table .root {
        width: 60px;
    }
    .price_table .smart {
        width: 60px;
    }
    .price_table .hdd {
        width: 120px;
    }
    .price_table .name {
        width: 80px;
    }
    .price_table .total_price {
        width: auto;
    }
}
@media screen and (max-width: 1150px) {
    .footer_spacer,
    footer {
        height: 235px;
    }
    .footer_top {
        height: 125px;
    }
    .footer_nav_elem ul {
        height: auto;
        padding-top: 10px;
    }
    .footer_nav_elem li {
        line-height: 32px;
    }
    .copy {
        padding-top: 13px;
        float: none;
        line-height: 25px;
    }
}
@media screen and (max-width: 1179px) {
    nav li {
        margin-right: 35px;
    }
    nav .nav_drop_down_handler:after {
        right: 7px;
    }
    nav .curr .nav_drop_down_handler:after {
        right: 0;
    }
}
@media screen and (min-width: 1079px) {
    .l .reason_line span {
        -ms-transform: skewY(-17deg); /* IE 9 */
        -webkit-transform: skewY(-17deg); /* Safari */
        transform: skewY(-17deg);
    }
    .r .reason_line span {
        -ms-transform: skewY(-12deg); /* IE 9 */
        -webkit-transform: skewY(-12deg); /* Safari */
        transform: skewY(-12deg);
    }
}
@media screen and (max-width: 1079px) {
    .price_table .price {
        width: 120px;
    }
}
@media screen and (max-width: 1050px) {
    nav li {
        margin-right: 25px;
    }
    nav .nav_drop_down_handler:after {
        right: 9px;
    }
    nav .curr .nav_drop_down_handler:after {
        right: 0;
    }
}


