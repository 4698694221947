.layout {
    min-width: 0;
}
.slider .info li {
    float: none;
}
.cols .cols_block {
    float: none;
}
@media screen and (max-width: 999px) {
    .slider .additional_nav.big li {
        width: 33.3%;
    }
    .square_container {
        left: -60px;
    }
    .tariffs_header {
        font-size: 50px;
        float: none;
    }
    .tariffs_hint {
        /* top: 103px; */
        left: 136px;
    }
    .tariffs_hint_text {
        padding-right: 100px;
    }

    .reason h3,
    .reason p {
        width: 275px;
    }
    .reason_icon {
        width: 240px;
    }

    .pay .l {
        width: 35%;
    }
    .pay .r {
        margin-left: 35%;
    }
    .price_table .os {
        display: none;
    }

    .footer_spacer,
    footer {
        height: 235px;
    }
    .footer_top {
        height: 125px;
    }
    .footer_nav_elem ul {
        height: auto;
        padding-top: 10px;
    }
    .footer_nav_elem li {
        line-height: 32px;
    }
    .copy {
        padding-top: 13px;
    }
}
@media screen and (max-width: 970px) {
    .lang a,
    .lang {
        height: 40px;
        line-height: 40px;
    }
    .header_main {
        height: 60px;
    }
    nav {
        height: 40px;
    }
    nav ul {
        display: none;
        position: fixed;
        z-index: 101;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 280px;
        background: #ffffff;
        padding-bottom: 20px;
        -webkit-box-shadow:  0 0 5px rgba(0,0,0,.4);
        -moz-box-shadow:  0 0 5px rgba(0,0,0,.4);
        box-shadow:  0 0 5px rgba(0,0,0,.4);
        overflow: auto;
    }
    .mode_link {
        display: block;
        font-size: 14px;
        text-transform: lowercase;
    }
    .mode_link .show_desktop {
        background: url('../i/ic_laptop_red.svg') 30px 50% no-repeat;
        padding-left: 54px;
    }
    nav ul.open {
        display: block;
    }
    .nav_phone {
        display: block;
    }
    .nav_phone a {
        padding-left: 60px;
        color: #000;
        background: url('../i/ic_phone_red.svg') 30px 50% no-repeat;
    }

    nav .first_nav_li,
    nav li {
        display: block;
        position: relative;
        z-index: 2;
        float: none;
        text-align: left;
        margin-right: 0;
        font-weight: 400;
        min-height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
    nav .nav_phone {
        border-bottom: 1px solid #ececec;
    }
    nav li .nav_drop_down_handler,
    nav li a {
        display: block;
        padding-left: 30px;
        margin-right: 0;
        min-height: 40px;
        line-height: 40px;
        color: #B00015;
        width: auto;
    }
    nav .first_nav_li {
        position: relative;
        z-index: 20;
        padding-left: 0;
        height: 112px;
        background: #B00015;
    }
    nav .first_nav_li .logo {
        width: auto;
        height: 40px;
        background: url('../i/logo-white.svg') 0 0 no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        margin-left: 30px;
        position: relative;
        margin-top: 60px;
        float: none;
    }
    nav .first_nav_li .lang {
        position: relative;
        z-index: 7;
        font-size: 14px;
        line-height: 40px;
        margin-right: 0;
        padding-right: 10px;
    }
    nav .first_nav_li .lang_list_container {
        background: #ececec;
    }
    nav .first_nav_li .lang_curr a {
        color: #fff;
    }
    nav .first_nav_li .lang a {
        text-shadow: none;
    }
    nav .first_nav_li p {
        color: #fff;
        text-shadow: none;
        padding-left: 30px;
        font-size: 14px;
        text-transform: none;
    }
    .mob_nav_change {
        display: block;
    }
    nav .nav_drop_down {
        position: static;
        width: auto;
        height: auto;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        float: none;
        /*border-top: 1px solid #ececec;*/
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
    nav .nav_drop_down ul {
        float: none;
        width: 100%;
        position: static;
        display: block;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin-bottom: 0;
        padding-bottom: 0;
        /*border-bottom: 1px solid #ececec;*/
    }
    nav .nav_drop_down ul:last-child  {
        border-bottom: 0;
    }
    nav .nav_drop_down li {
        display: block;
        font-size: 14px;
        width: auto;
        margin: 0 !important;
        padding: 0;
        text-align: left;
        height: auto;
    }
    nav .nav_drop_down li:last-child {
        border-bottom: 0;
    }
    nav .nav_drop_down li a {
        padding: 5px 0 5px 60px;
        line-height: 30px;
    }
    nav .nav_drop_down li a:hover {
        color: #B00015;
        background: #fff;
    }
    nav .nav_drop_down_handler:after {
        vertical-align: top;
        position: static;
        display: inline-block;
        line-height: 40px;
        height: 40px;
        width: 20px;
        background: url('../i/ic_arrow_drop_down_red.svg') 50% 50% no-repeat;
    }
    nav .nav_drop_down_handler.opened:after {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }


    nav .first_nav_li.mob_nav_4 .logo,
    nav .first_nav_li.mob_nav_5 .logo {
        background: url('../i/logo_Imena_clean.svg') 0 0 no-repeat;
    }
    nav .first_nav_li.mob_nav_4 p,
    nav .first_nav_li.mob_nav_5 p {
        color: #000;
    }
    nav .first_nav_li.mob_nav_4 .lang_curr a,
    nav .first_nav_li.mob_nav_5 .lang_curr a {
        color: #000;
    }
    nav .mob_nav_4 .lang_curr:after,
    nav .mob_nav_5 .lang_curr:after {
        background: url('../i/ic_arrow_drop_down_blue.svg') 50% 50% no-repeat;
        s-webkit-background-size: cover;
        sbackground-size: cover;
    }
    nav .mob_nav_4 .lang_list_container,
    nav .mob_nav_5 .lang_list_container {
        background: #fff;
    }

    nav .curr .nav_drop_down_handler,
    nav .curr a {
        border: none;
    }
    nav li:first-child {
        margin-top: 20px;
    }
    nav .enter_btn {
        font-size: 15px;
        line-height: 38px;
        height: 40px;
    }
    nav .enter_block {
        top: 40px;
    }
    nav .enter_block .enter_block_black a {
        height: 40px;
        line-height: 38px;
    }
    nav .nav_icon {
        width: 40px;
        height: 40px;
    }
    nav.open_mobile_nav:before {
        content: '';
        position: fixed;
        z-index: 11;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: rgba(0,0,0,.7);
        font-size: 0;
        line-height: 0;
        padding: 0;
    }
    nav .menu_nav_close {
        display: none;
    }
    nav .menu_nav_icon {
        display: none;
        width: 50px;
        height: 130px;
        position: absolute;
        z-index: 3;
        left: 0px;
        top: 20px;
        margin: 0;
        background: #f7f7f7 url('../i/ic_arrow_back_red.svg') 50% 50% no-repeat;
        padding: 0;
    }
}
@media screen and (max-width: 919px) {
    /*
    nav li {
        margin-right: 25px;
    }
    */
    .square_container {
        left: -40px;
    }
    .tariffs_header {
        font-size: 40px;
        float: none;
    }
    .tariffs_hint {
        top: 125px;
        left: 112px;
    }
    .square_container {
        -ms-transform: scale(0.85, 0.85); /* IE 9 */
        -webkit-transform: scale(0.85, 0.85); /* Safari */
        transform: scale(0.85, 0.85);
    }

    .price_table.price1 .price,
    .price_table .price {
        width: 100px;
    }
    .price_table .os {
        display: none;
    }
    .price_table .total_btn {
        display: block;
        margin-top: 10px;
    }

    .l .reason_line span {
        -ms-transform: skewY(-37deg); /* IE 9 */
        -webkit-transform: skewY(-37deg); /* Safari */
        transform: skewY(-37deg);
    }
    .r .reason_line span {
        -ms-transform: skewY(-30deg); /* IE 9 */
        -webkit-transform: skewY(-30deg); /* Safari */
        transform: skewY(-30deg);
    }

    .contact .address_lines {
        width: 305px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .contact .form {
        margin-left: 345px;
        padding-left: 50px;
        padding-right: 50px;
        background-position: 10px 45px;
    }
}
@media screen and (max-width: 859px) {
    .spacer {
        margin: 0;
    }
    .table_select {
        display: block;
        margin-bottom: 25px;
    }
    .price_table.table,
    .price_table .tr {
        soverflow: hidden;
        display: block;
        width: auto;
    }
    .price_table.sales {
        background-color: transparent;
    }
    .price_table.sales .td {
        background: #fbf2f3;
    }
    .price_table.sales .td.price {
        background-color: transparent;
    }
    .price_table.sales .td,
    .price_table_cells.sales .td:after{
        background: #fbf2f3;
    }
    .price_table .name {
        overflow: hidden;
    }
    .price_table .name strong {
        height: 100%;
        line-height: 47px;
        font-size: 14px;
    }
    .price_table_header,
    .price_table_header.tr,
    .price1 .price_table_header.tr,
    .price_table_header.price_table {
        float: left;
        width: 50%;
        margin-right: -100%;
        display: none;
    }
    .price_table_header .th {
        display: block;
        width: auto;
        border-top: none;
        border-right: 0;
    }
    .price_table .th.price {
        display: none;
    }
    .price_table .price_box {
        display: block;
        width: auto;
    }
    .price_table .nodiscount_price {
        width: 115px;
        display: inline-block;
        text-decoration: line-through;
    }
    .price_table .td.price {
        display: block;
        width: 200%;
        margin-left: -100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: none;
    }
    .price_table .total_price {
        display: block;
        vertical-align: baseline;
        margin: 0 auto;
    }
    .price_table .td.price .total_btn {
        display: inline-block;
        vertical-align: top;
    }
    .price_table_cells.tr,
    .price_table_cells.price_table,
    .price_table_cells {
        overflow: hidden;
    }
    .price_table .tr {
        padding: 0 0 20px;
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: 20px;
    }
    .price_table_cells .td {
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        text-align: left;
        padding-left: 50%;
    }
    .price_table .name span {
        display: inline-block;
        font-size: 20px;
    }
    .price_table_cells .td:before {
        content: attr(data-title);
        width: 96%;
        display: inline-block;
        position: relative;
        z-index: 5;
        margin-right: 0;
        margin-left: -100%;
        vertical-align: top;
        float: left;
    }
    .price_table_cells .td:after {
        content: '';
        background: #f3f3f3;
        position: absolute;
        z-index: 1;
        left: 0;
        top: -1px;
        width: 48%;
        height: 100%;
        border-top: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        sborder-bottom: 1px solid #d9d9d9;
    }
    .table.price_table  .td.name {
        padding: 10px 0;
        text-align: center;
        border-left: none;
        border-right: none;
        border-top: none;
        font-size: 20px;
    }
    .price_table .td.name:before {
        content: attr(data-title);
        width: auto;
        display: inline-block;
        margin-right: 5px;
        margin-left: 0;
        vertical-align: top;
        float: none;
    }
    .price_table .td.name:after {
        display: none;
    }
    .price_table_cells .td.price {
        padding-bottom: 30px;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding:0;
        text-align: center;
    }
    .price_table_cells .td .checkbox .icon {
        margin: 0;
    }
    .price_table_cells .td.price:after,
    .price_table_cells .td.price:before {
        display: none;
    }
    .price_table_cells.open {
    }
    .price_table_cells .td {
        display: block;
        width: auto;
        stext-align: left;
    }
    .price_table_header .th:first-child,
    .price_table_cells .td:first-child {
        border-top: 1px solid #d9d9d9;
    }
    .price_table .name {
        min-height: 30px;
    }
    .price_table .name.open {
        height: 85px;
    }

    .smart_about {
        border-bottom: none;
        background-color: transparent;
    }
    .price_table.price1 .total_price {
        width: 100%;
    }
    .price_table .total_price span {
        font-size: 30px;
    }

    .price_table .total_price_before .tr {
        padding: 0;
        margin: 0;
        border: none;
    }
    .price_table .total_price_before .td {
        display: table-cell;
        padding: 10px;
        border-top: none;
    }
    .price_table .total_price_before .td:before,
    .price_table .total_price_before .td:after {
        display: none;
    }
}
@media screen and (max-width: 839px) {
    .tariffs_hint {
        top: 217px;
        left: 112px;
    }
    .tariffs_list {
        display: block;
        overflow: hidden;
    }
    .tariffs_list li {
        display: block;
        float: left;
        width: 25%;
        line-height: 40px;
        margin-bottom: 15px;
    }
    .tariffs_note {
        font-size: 18px;
        width: 250px;
    }
    .tariffs_btn {
        font-size: 20px;
    }
    .tariff_data_2 {
        left: 275px;
    }
    .square_container {
        -ms-transform: scale(0.75, 0.75); /* IE 9 */
        -webkit-transform: scale(0.75, 0.75); /* Safari */
        transform: scale(0.75, 0.75);
    }


    .contact .address_lines {
        width: 255px;
    }
    .contact .form {
        margin-left: 295px;
        padding-left: 50px;
        padding-right: 50px;
        background-position: 10px 45px;
    }

    .l .reason_line span {
        -ms-transform: skewY(-47deg); /* IE 9 */
        -webkit-transform: skewY(-47deg); /* Safari */
        transform: skewY(-47deg);
    }
    .r .reason_line span {
        -ms-transform: skewY(-38deg); /* IE 9 */
        -webkit-transform: skewY(-38deg); /* Safari */
        transform: skewY(-38deg);
    }

    .contact .l {
        width: 300px;
    }
    .contact .r {
        margin-left: 300px;
    }
}
@media screen and (max-width: 767px) {
    header {
        height: 70px;
    }
    .logo {
        margin-top: 9px;
    }
    .header_btns {
        float: right;
        clear: right;
        padding: 0;
    }
    .header_contacts {
        padding-top: 4px;
    }
    .header_contacts a {
        font-size: 24px;
        line-height: 20px;
    }
    .header_contacts span {
        font-size: 16px;
        display: inline-block;
        margin: 5px 0 10px;
    }
    .header_btn {
        padding-right: 0;
    }

    nav .first_nav_li {
        height: 128px;
    }


    /*
    .enter_block_content {
        padding: 35px;
    }
    .enter_block_close {
        width: 40px;
        height: 35px;
        line-height: 35px;
        font-size: 32px;
    }
    */
    .enter_block {
        top: 40px;
    }
    .enter_block .enter_block_black a {
        height: 40px;
        line-height: 40px;
        text-shadow: none;
    }

    .slider h2 {
        font-size: 26px;
        padding-top: 15px;
    }
    .slider h2 span {
        font-size: 20px;
    }
    .slider .info.no_indent {
        padding-left: 50px;
    }

    .hosting_description {
        display: block;
    }
    .hosting_description .separator {
        display: none;
    }
    .hosting_description .tr {
        display: block;
    }
    .hosting_description a.td,
    .hosting_description a.td.curr {
        display: block;
        width: auto;
        overflow: hidden;
        margin-bottom: 20px;
        padding-bottom: 0;
    }
    .hosting_description .hosting_description_elem_bg .ovh {
        overflow: visible;
    }
    .hosting_description_icon {
        float: left;
        margin-right: -100%;
        margin-left: 17px;
    }
    .hosting_description h2,
    .hosting_description p {
        margin-left: 160px;
        text-align: left;
        padding-left: 0;
    }
    .hosting_description_order {
        position: static;
    }
    .hosting_description_price {
        position: absolute;
        right: 17px;
        top: 12px;
    }
    .hosting_description .curr .hosting_description_elem_bg,
    .hosting_description .hosting_description_elem_bg {
        height: auto;
        top: 0;
        padding-bottom: 0;
        overflow: hidden;
        position: relative;
    }
    .hosting_description .hosting_description_elem_bg.big {
        height: auto;
        margin-bottom: 0;
    }
    .hosting_description .curr .hosting_description_btn {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
    }
    .hosting_description .hosting_description_elem_bg.big:before {
        display: none;
    }
    .hosting_description .hosting_description_elem_bg.big .hosting_description_icon {
        margin-bottom: 0;
        top: 0;
    }
    .hosting_description .hosting_description_elem_bg.big h2,
    .hosting_description .hosting_description_elem_bg.big p {
        margin-top: 0;
    }

    .hosting_description.bottom .hosting_description_elem_bg {
        padding: 0;
    }
    .hosting_description.bottom h5 {
        text-align: left;
        margin-left: 140px;
    }
    .hosting_description.bottom ul {
        text-align: left;
        margin-left: 160px;
    }

    /*
    .hosting_description .hosting_description_action {
        position: relative;
        width: auto;
        height: 30px;
    }
    .hosting_description .hosting_description_action div {
        background: #fbf2f3;
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);

        position: relative;
        left: 0;
        top: 0;
        width: auto;
        height: 40px;
        text-align: center;
        line-height: 30px;
    }
    .hosting_description .hosting_description_action span {
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;

        position: static;
        width: auto;
        text-align: left;
        padding-left: 0;
        padding-top: 0;
        font-size: 14px;
        line-height: 30px;
    }
    */

    .description h3 {
        font-size: 15px;
    }
    .description .button {
        font-size: 13px;
    }
    .description .block {
        padding-left: 10px;
        padding-right: 10px;
    }

    .contact_table,
    .contact_table .tr,
    .contact_table .td {
        display: block;
    }
    .contact .address_lines {
        width: auto;
    }
    .contact .address_line {
        padding-bottom: 10px;
        padding-top: 12px;
    }
    .contact .address_line h3 {
        padding-top: 0;
    }
    .contact .address_line.phone_icon {
        background-position: 20px 17px;
    }
    .contact .address_line.email_icon {
        background-position: 20px 10px;
    }
    .contact .form {
        margin-left: 0;
        width: auto;
    }

    .order .l,
    .order .r {
        float: none;
        width: auto;
        margin: 0;
    }
    .order .l h1 {
        padding-bottom: 0;
    }
    .order .r {
        padding-bottom: 20px;
    }


    .reasons {
        position: relative;
        padding-top: 0px;
    }
    .reasons .l,
    .reasons .r {
        float: none;
        margin: 0;
        padding: 0;
        width: auto;
    }
    .reasons .first {
        padding-top: 0px;
    }
    .reasons_center_line {
        display: none;
    }
    .reason {
        height: auto;
        margin-bottom: 30px;
        overflow: hidden;
    }
    .reason h3,
    .reason p {
        width: auto;
        margin-left: 140px;
    }
    .reason_icon_block,
    .reason_icon_block .table {
        width: auto;
        display: block;
        float: left;
        margin-right: -100%;
        margin-top: 5px;
    }
    .reason_icon {
        width: auto;
        padding: 0;
        border: none;
    }
    .l .reason_num,
    .r .reason_num {
        display: none;
    }
    .reason_icon span {
        display: block;
        width: 120px;
        height: 120px;
        background: #787878;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        margin: 0 auto;
    }
    .reason_line {
        display: none;
    }
    .l .reason {
        margin-left: 0;
    }
    .r .reason_icon_block {
        margin-right: -100%;
    }
    .r .reason h3,
    .r .reason p {
        float: none;
        clear: none;
    }
    .reason > a {
        margin-left: 140px;
    }

    .reviews .l {
        float: none;
        padding-right: 0;
    }
    .reviews .r {
        width: auto;
        float: none;
    }

    .full .payment,
    .payment {
        width: 25%;
        margin-bottom: 10px;
    }


    .support_form,
    .support_form .tr,
    .support_form .td {
        display: block;
        overflow: hidden;
    }
    .support_form .table input {
        border-right: 1px solid #B00015;
    }

    .three_cols {
        display: block;
    }
    .three_cols .td {
        display: block;
        padding-right: 0;
    }
    .slider h1 {
        font-size: 30px;
    }
    .slider .info {
        margin-top: 20px;
    }
    .slider .note {
        font-size: 14px;
    }


    .footer_logos {
        height: auto;
        padding: 20px 0 10px;
    }
    .footer_logos_table {
        display: block;
        height: auto;
        text-align: center;
        font-size: 0;
    }
    .footer_logos_table a {
        display: inline-block;
        width: 20%;
        height: 50px;
        margin-bottom: 10px;
    }

    .e404 .r {
        display: none;
    }
    .e404 .l {
        float: none;
        width: auto;
        margin-right: 0;
    }


    #mirohost-buttons table,
    #mirohost-buttons tbody,
    #mirohost-buttons tr,
    #mirohost-buttons td {
        display: block;

    }
    .mirohost-buttons-list {
        padding-top: 10px;
        padding-left: 0;
    }
    .mirohost-buttons-list img {
        margin-left: 0;
        margin-right: 6px;
    }

    .text_table,
    .text_table tbody,
    .text_table tr,
    .text_table td {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    .text_table tr td:first-child {
        padding-bottom: 0;
    }
    .text_table tr td:last-child {
        padding-bottom: 20px;
    }
}
@media screen and (max-width: 700px) {
    .slider .additional_nav.big li {
        width: 50%;
    }
    .tariffs_about {
        float: none;
        width: auto;
    }
    .tariffs_order {
        padding-top: 10px;
    }
    .tariffs_note {
        float: left;
    }
    .square_container {
        clear: both;
        float: none;
        margin: 0 auto;
    }
    .tariffs_btn {
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        margin-left: 90px;
    }
    .tariffs_price span {
        font-size: 30px;
        line-height: 30px;
    }
    .square_container {
        left: 0;
    }
    .tariffs_hint_text {
        padding-right: 0;
    }
    form .payment-form.wide {
        padding: 5px 40px 0;
    }
    table.half,
    .half.table {
        width: 100%;
    }
}
@media screen and (max-width: 680px) {

    .order_table.order_domain .th,
    .order_table.order_domain .th,
    .wide .order_table.order_domain .td,
    .wide .order_table.order_domain .td {
        padding-right: 0;
        padding-left: 0;
    }
    form .check_domain.wide {
        padding: 5px 10px 0;
    }
    form .check_domain.wide .checkbox,
    form .check_domain.wide .td.input,
    form .check_domain .checkbox,
    form .check_domain .td.input {
        width: 34px;
    }
}
@media screen and (max-width: 599px) {
    header {
        height: 74px;
    }
    nav .first_nav_li {
        height: 132px;
    }

    .lang_list_container .radio .icon,
    .lang_list_container .radio.left .icon {
        margin-top: 3px;
        border-bottom: none;
        display: block;
    }
    .lang_list_container .radio a:hover {
        background-color: transparent;
        color: #03a9f4;
    }
    .lang_list_container>div  {
        position: relative;
    }
    .lang_list_container>div a  {
        position: relative;
        z-index: 2;
        padding-left: 40px;
        background-position: 37px 50% !important;
        padding-right: 10px;
    }

    .slider_elem {
        height: auto;
        padding-bottom: 30px;
    }

    .hosting_description a.td {
        display: block;
        width: auto;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .hosting_description .hosting_description_elem_bg .ovh {
        overflow: hidden;
    }
    .hosting_description_icon {
        float: none;
        margin-right: auto;
        margin-left: auto;
    }
    .hosting_description h2,
    .hosting_description p {
        margin-left: 0px;
        text-align: center;
    }
    .hosting_description p {
        padding-left: 17px;
    }
    .hosting_description_order {
        position: absolute;
    }
    .hosting_description_price {
        position: static;
    }
    .hosting_description .curr .hosting_description_elem_bg,
    .hosting_description .hosting_description_elem_bg {
        height: auto;
        padding-bottom: 125px;
    }
    .hosting_description .hosting_description_elem_bg.big {
        height: auto;
    }

    .hosting_description.bottom .hosting_description_elem_bg {
        padding: 0;
    }
    .hosting_description.bottom h5 {
        text-align: center;
        margin-left: 0;
    }
    .hosting_description.bottom ul {
        text-align: center;
        margin-left: 0;
    }

    .description .description_title {
        font-size: 24px;
        line-height: 38px;
    }
    .description .table,
    .description .tr {
        display: block;
        overflow: hidden;
    }
    .description .button {
        display: none;
    }
    .description .block {
        padding: 0;
        float: left;
        width: 50%;
        display: block;
        text-decoration: underline;
        margin-bottom: 15px;
    }
    .description .block_bg {
        height: 65px;
    }
    .description .block1 .block_bg {
        width: 40px;
        background: url('../i/principle_security.svg') 50% 50% no-repeat;
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
    }
    .description .block2 .block_bg {
        width: 50px;
        background: url('../i/principle_support.svg') 50% 50% no-repeat;
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
    }
    .description .block3 .block_bg {
        width: 50px;
        background: url('../i/principle_speed.svg') 50% 50% no-repeat;
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
    }
    .description .block4 .block_bg {
        width: 50px;
        background: url('../i/principle_flex.svg') 50% 50% no-repeat;
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
    }

    .list_elem {
        width: 25%;
    }

    .cols .l,
    .slider .l {
        width: auto;
        margin-right: 0;
        float: none;
    }
    .cols .r,
    .slider .r {
        margin-left: 0;
    }
    .cols .l,
    .cols .r {
        padding: 0;
    }
    .slider h1 {
        font-size: 28px;
        padding-top: 10px;
        padding-bottom: 7px;
    }
    .layout .slider .info {
        padding-left: 65px;
        background-position: 0 50%;
        margin-top: 10px;
    }
    .slider .note {
        padding-top: 20px;
        font-size: 14px;
        line-height: 24px;
    }
    .slider .info.no_indent {
        padding-left: 0;
    }

    .enter_form {
        padding: 20px 30px;
    }

    .lang_list,
    nav .enter_block {
        position: fixed;
        z-index: 100;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.7);
        text-align: center;
    }
    .lang_list_container,
    nav .enter_block_black {
        width: 280px;
        height: 160px;
        position: absolute;
        right: 50%;
        bottom: 50%;
        margin-right: -140px;
        margin-bottom: -80px;
    }
    .lang_list_container {
        width: 90px;
        height: 120px;
        margin-right: -45px;
        margin-bottom: -60px;
        text-align: left;
    }
    nav .lang_list_container div {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid #ececec;
        height: 40px;
    }
    nav .lang_list_container div:last-child {
        border: none;
    }


    .tariffs_note {
        float: none;
        margin: 0;
        width: auto;
    }
    .tariffs_note span {
        display: block;
    }
    .tariffs_order {
        padding-top: 30px;
        margin-left: 180px;
        margin-top: -85px;
        position: relative;
        z-index: 10;
    }

    .tariffs_bottom_text,
    .tariffs_bottom_text:last-child {
        display: table;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        line-height: normal;
        font-size: 15px;
    }
    .tariffs_bottom_text span,
    .tariffs_bottom_text em {
        float: none;
        display: table-cell;
        margin: 0;
        vertical-align: middle;
        position: relative;
        border: none;
    }
    .tariffs_bottom_text span:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 60px;
        border: 1px solid #929497;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
    }
    .tariffs_bottom_text em {
        text-decoration: underline;
        border-bottom: none;
        padding-left: 20px;
    }

    form .payment-form.wide {
        padding: 5px 30px 0;
    }
    .order_table .th,
    .order_table .td {
        font-size: 13px;
    }
    .order_steps_total_num {
        font-size: 16px;
        margin: 0;
    }

    .contact .form {
        padding-left: 35px;
        padding-right: 35px;
    }

    .footer_spacer,
    footer {
        height: 335px;
    }
    .footer_top {
        height: 165px;
    }
    .footer_nav_elem ul {
        height: auto;
        padding-top: 10px;
    }
    .footer_nav_elem li {
        line-height: 32px;
    }
    .copy {
        padding-top: 13px;
    }

    .border_table.data_media,
    .border_table.data_media tbody,
    .border_table.data_media .tbody {
        display: block;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .border_table.data_media tr,
    .border_table.data_media .tr {
        display: block;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        margin-bottom: 10px;
        border-top: 1px solid #d9d9d9;
    }
    .border_table.data_media tr:first-child,
    .border_table.data_media .tr:first-child {
        display: none;
    }
    .border_table.data_media td,
    .border_table.data_media .td {
        display: block;
        width: 100%;
        padding-left: 50%;
        position: relative;
        min-height: 40px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-top: none;
        overflow: hidden;
    }
    .border_table.data_media td:before,
    .border_table.data_media .td:before {
        content: attr(data-th);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        left: -100%;
        z-index: 2;
        width: 100%;
        top: 0;
        padding: 10px 10px 0;
        margin-right: -100%;
        margin-top: -10px;
        margin-left: -10px;
        float: left;
    }
    .border_table.data_media td:after,
    .border_table.data_media .td:after {
        content: '';
        z-index: 1;
        width: 48%;
        height: 100%;
        background: #f3f3f3;
        position: absolute;
        left: 0;
        top: 0;
        border-right: 1px solid #d9d9d9;
    }
}
@media screen and (max-width: 550px) {
    form .check_domain.wide {
        padding: 5px 0 0;
        overflow: hidden;
    }
    .wide .order_table .td.small,
    .order_table .td.small {
        width: 120px;
    }

    .h-baner {
        max-width: 100%;
    }
    .h-baner .hosting_description_icon {
        margin: 17px auto;
        float: none;
    }
    .h-baner .hosting_description_elem_bg {
        text-align: center;
    }
}
@media screen and (max-width: 479px) {
    header {
        height: 50px;
    }
    nav .first_nav_li {
        height: 108px;
    }
    .logo {
        margin-top: 0;
        height: 35px;
        width: 150px;
    }
    .logo img {
        height: 25px;
        width: 105px;
        height: 35px;
        width: auto;
    }
    header .header_l {
        float: none;
        margin: 0;
    }
    header .logo {
        margin: 0 0 15px;
    }
    header .logo_txt {
        display: none;
    }
    header .header_r {
        float: none;
        margin: 0;
        width: auto;
    }
    header .header_contacts_phone {
        text-align: left;
    }
    header .header_contacts_phone span,
    header .header_contacts_phone br {
        display: none;
    }
    header .header_btns {
        float: none;
        text-align: left;
    }
    nav .menu_nav_icon {
        stop: 98px;
    }

    .slider .additional_nav.big li {
        width: auto;
        margin-right: 15px;
        padding-bottom: 12px;
        font-size: 12px;
    }

    h2 {
        font-size: 28px;
    }

    /*
    .enter_block {
        position: fixed;
        z-index: 120;
        width: 100%;
        height: 100%;
        top: auto;
        left: 0;
        bottom: 0;
        background: rgba(255,255,255,0.95);
    }
    .enter_block_arr,
    .enter_block_arr_red {
        display: none;
    }
    .enter_block_content {
        background: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: none;
        position: static;
        min-width: 1px;
    }
    .enter_block_content table {
        width: 100%;
    }
    .enter_block_content .tabs {
        width: auto;
        height: auto;
        display: block;
        border: none;
    }
    .enter_block_content .tab {
        display: block;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
    }
    .enter_block_content .tab.curr {
        height: 30px;
        line-height: 30px;
        border-width: 1px;
    }
    .enter_block_content .tab br {
        display: none;
    }
    */

    .price_table .total_btn {
        font-size: 18px;
    }
    .price_table .total_price span {
        sfont-size: 34px;
    }
    .price_table.price1 .total_price {
        width: auto;
    }


    .full .payment,
    .payment {
        width: 50%;
        margin-bottom: 10px;
    }

    table.btns,
    table.btns tbody,
    table.btns tr,
    table.btns td {
        display: block;
        overflow: hidden;
    }
    table.btns .capcha {
        float: right;
    }

    .list_elem {
        width: 50%;
    }
    .order_table .input {
        width: 30px;
    }
    .order_table .period {
        width: 45px;
    }

    .reason h3,
    .reason p {
        width: auto;
        margin-left: 0;
        text-align: center;
    }
    .reason_icon_block,
    .reason_icon_block .table,
    .reason_icon_block .reason_icon {
        float: none;
        width: 100%;
        display: block;
        text-align: center;
    }


    .enter_form {
        padding: 15px;
    }
    .enter_form .radio.left h4 {
        margin-left: 34px;
        line-height: 34px;
    }

    .tariffs_order {
        margin-left: 0;
        margin-top: 0;
    }
    .square_container {
        -ms-transform: scale(0.6, 0.6);
        -webkit-transform: scale(0.6, 0.6);
        transform: scale(0.6, 0.6);
        margin-top: -50px;
        margin-bottom: -50px;
    }
    .tariff_data_line {
        display: none;
    }
    .tariff_data_1 {
        top: -15px;
        left: -85px;
    }
    .tariff_data_2 {
        top: -35px;
        left: 205px;
    }
    .tariff_data_3 {
        top: 345px;
        left: 105px;
    }
    form .payment-form.wide {
        padding: 5px 15px 0;
        overflow: hidden;
    }
    .radio.left h4 {
        font-size: 15px;
        margin-left: 40px;
    }
    .radio.left p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 40px;
    }
    .radio.left .txt {
        margin-left: 0;
    }

    .price_table .th,
    .price_table .td {
        font-size: 13px;
    }

    .payment-form .order_steps_total {
        display: table;
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
    }
    .payment-form .order_steps_total .input_nav_prev {
        display:table-cell;
        margin: 0;
        width: 40px;
        vertical-align: middle;
    }
    .payment-form .order_steps_total .button-right .order_steps_total_prev {
        margin: 0;
    }
    .payment-form .order_steps_total .order_steps_total_num {
        display:table-cell;
        margin: 0;
        vertical-align: middle;
        line-height: 24px;
    }
    .payment-form .order_steps_total .button-right {
        display:table-cell;
        margin: 0;
        width: 40px;
        vertical-align: middle;
    }
    .payment-form .order_steps_total .button-right .order_steps_total_next {
        margin: 0;
    }

    .contact .form {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
    }

    .contact .form td {
        display: table-cell;
    }
    .contact .form table.btns {
        display: table;
    }
    .contact .form table.btns tbody {
        display: table-row-group;
    }
    .contact .form table.btns tr {
        display: table-row;
    }

    .slider .red_btn {
        height: auto;
        font-size: 12px;
        line-height: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .footer_logos_table div {
        display: inline-block;
        width: 33%;
        height: 50px;
        margin-bottom: 10px;
    }

    .colocation_position.table {
        border-left: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
    }
    .colocation_position.table,
    .colocation_position.table tbody,
    .colocation_position.table tr {
        display: block;
        overflow: hidden;
    }
    .colocation_position.table td.th {
        display: block;
        height: auto;
        width: auto;
        font-size: 14px;
        line-height: normal;
        clear: both;
        border-left: none;
        border-right: none;
    }
    .colocation_position.table td.td {
        float: left;
        width: 20%;
        border-top: none;
        border-bottom: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-left: none;
        /*border-right: none;*/
    }
    .colocation_position.table td.colspan {
        width: 100%;
    }
    .colocation_position.table span.by-media {
        display: none;
    }
    .colocation_position.table td.td:last-child {
        /*border-left: 1px solid #d9d9d9;*/
        border-right: none;
    }
    .colocation_position.table tr:last-child {
        border-bottom: 1px solid #d9d9d9;
    }
    .colocation_position.table tr:last-child td.th {
        display: none;
    }

    .colocation_position.table .total_btn {
        font-size: 9px;
        padding: 0 2px;
    }

    .new_domain_table,
    .new_domain_table tbody,
    .new_domain_table tr,
    .new_domain_table td {
        display: block;
    }
    .new_domain_table tr:first-child td {
        margin-left: 65px;
    }
    .new_domain_table tr:first-child td.icon {
        float: left;
        margin-right: -100%;
        margin-left: 0;
    }
    .new_domain_table tr:first-child td:last-child {
        margin-left: 0;
        padding-left: 0 !important;
    }

    #mirohost-buttons .btn {
        float: none;
        width: 145px;
        margin: 0 auto;
    }
    .mirohost-buttons-list {
        width: auto;
        padding-left: 0;
    }
    .mirohost-buttons-list .l {
        float: none;
        width: 200px;
        margin: 0 auto;
    }
    .mirohost-buttons-list .r {
        float: none;
        width: 200px;
        margin: 0 auto;
    }
    .mirohost-buttons-list img {
        margin-left: 3px;
        margin-right: 3px;
    }

    table.half,
    .half.table,
    table.half tbody,
    .half.table tbody,
    table.half tr,
    .half.table tr,
    table.half th,
    .half.table th,
    table.half td,
    .half.table td {
        display: block;
    }
    table.half tr,
    .half.table tr {
        margin-bottom: 10px;
    }
    table.half td,
    .half.table td {
        border-top: none;
    }
}
@media screen and (max-width: 420px) {
    .footer_nav_elem ul {
        padding-top: 5px;
    }
    .footer_nav_elem li {
        line-height: 26px;
    }
    .copy {
        padding-top: 8px;
    }
}
@media screen and (max-width: 379px) {
    .contact .form table.btns,
    .contact .form table.btns tbody,
    .contact .form table.btns tr,
    .contact .form table.btns td {
        display: block;
        overflow: hidden;
    }
    .contact .form table.btns td button {
        float: none;
    }
    .footer_spacer {
        height: 175px;
    }
    footer {
        height: 175px;
    }
    .domain_list_line {
        display: block;
    }
    .domain_list_elem {
        display: inline-block;
        width: auto;
        vertical-align: top;
        margin-right: 20px;
    }

}